import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {apiGetLastNews, apiGetNewsById} from "../../Landing/actions/api";
import {toast} from "react-toastify";
import {Box, Button, Chip, CircularProgress, Stack, Typography, useTheme} from "@mui/material";
import PageHeader from "../../global/components/PageHeader";
import DOMPurify from "dompurify";
import {formatDate} from "../../global/helpers/text";
import {StandaloneNewsInfoType} from "../../global/actions/types.api";
import {SkipNext, SkipPrevious} from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTranslation} from "react-i18next";
import 'react-quill/dist/quill.snow.css';


const NewsPage = () => {

  const {newsId} = useParams();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [newsInfoResponse, newsInfoLoading, newsInfoError, requestNewsInfo] = useLoadingHook<StandaloneNewsInfoType>(apiGetNewsById);
  const [sanitizedContent, setSanitizedContent] = useState<any>({
    sanitized_content_ru: '',
    sanitized_content_kz: ''
  });

  useEffect(() => {
    if (newsId) {
      requestNewsInfo(newsId);
    } else {
      toast.error('Такая новость отсутствует в базе данных.')
    }
  }, [newsId]);

  useEffect(() => {
    if (newsInfoResponse) {
      const sanitizedContentRu = DOMPurify.sanitize(newsInfoResponse.content_ru.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'));
      const sanitizedContentKz = DOMPurify.sanitize(newsInfoResponse.content_kz.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'));

      setSanitizedContent({
        sanitized_content_ru: sanitizedContentRu,
        sanitized_content_kz: sanitizedContentKz,
      });
    }
  }, [newsInfoResponse]);

  const NextPreviousButton = ({type, id} : any) => {
    return (
      <Button
        variant={'outlined'}
        startIcon={type === 'next' ? <SkipNext /> : <SkipPrevious />}
        disabled={!id}
        onClick={() => {navigate(`/news/${id}`)}}
        sx={{
          fontSize: isMobile ? '12px' : '14px',
          width: 'fit-content'
        }}
      >
        {type === 'next' ? t('news-page.next') : t('news-page.previous')}
      </Button>
    )
  }

  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column', pb: 2}}>
      <PageHeader />

      <Stack
        flexDirection={'column'}
        gap={'16px'}
        sx={{
          mx: 5,
          px: 2,
          py: 2,
          background: '#fff',
          borderRadius: '16px'
        }}
      >
        {newsInfoResponse && (
          <img
            src={newsInfoResponse?.thumbnail}
            style={{
              width: isMobile ? '100%' : '800px',
              height: isMobile ? 'auto' : '400px',
              objectFit: 'cover', // Сохраняет пропорции и обрезает изображение, если оно превышает размеры
              objectPosition: 'center', // Центрирует изображение в рамке
              borderRadius: '16px'
            }}
          />
        )}
        {(!newsInfoResponse || newsInfoLoading) && (<CircularProgress />)}
        {newsInfoResponse && (
          <Typography
            variant={'h5'}
            fontWeight={'bold'}
          >
            {i18n.language === 'ru' ? newsInfoResponse?.title_ru : newsInfoResponse?.title_kz}
          </Typography>
        )}
        {newsInfoResponse && (
          <Stack
            sx={{
              fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
              whiteSpace: 'pre-wrap',
            }}
            className={'ql-editor'}
            dangerouslySetInnerHTML={{ __html: i18n.language === 'ru' ? sanitizedContent.sanitized_content_ru : sanitizedContent.sanitized_content_kz}}
          />
        )}
        {newsInfoResponse && (
          <Chip label={`${t('news-page.created_at')} ${formatDate(newsInfoResponse?.created_at)}`} sx={{width: 'fit-content'}}/>
        )}
        {newsInfoResponse && (
          <Stack
            flexDirection={'row'}
            justifyContent={isMobile ? 'flex-start' : 'space-between'}
            gap={isMobile ? '16px' : '0'}
            sx={{
              mt: isMobile ? '20px' : '44px'
            }}
          >
            <NextPreviousButton
              type={'previous'}
              id={newsInfoResponse?.previous?.id}
            />

            <NextPreviousButton
              type={'next'}
              id={newsInfoResponse?.next?.id}
            />
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default NewsPage;
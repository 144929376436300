import React from 'react';
import {Box, InputBase, Stack, Tab, Tabs, TextField, Typography} from "@mui/material";
import {DataGrid, GridColDef, GridEventListener} from "@mui/x-data-grid";
import CustomDataGrid from "../../global/components/CustomDataGrid";

interface AppealsGridProps {
    openAppeal: (id: number) => void,
    rows: any[],
    setTab: (newTab: number) => void,
    tab: number,
    loading: boolean,
    setFilter: (filter: string) => void,
    filter: string,
}

const NoRows = () => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', p: 5}}>
            <Typography>
                Нет данных
            </Typography>
        </Box>
    )
}

const AppealsGrid = ({openAppeal, rows, setTab, tab, loading, setFilter, filter}: AppealsGridProps) => {

    const columns: GridColDef[] = [
        {
            field: 'empty',
            headerName: '',
            headerClassName: 'datagrid--header',
            maxWidth: 10,
            width: 10,
            sortable: false,
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 90
        },
        {
            field: 'bin',
            headerName: 'БИН учебной организации',
            width: 200
        },
        {
            field: 'title',
            headerName: 'Название учебной организации',
            width: 500
        },
        {
            field: 'phone1',
            headerName: 'Телефон учебной организации',
            width: 300
        },
        {
            field: 'iin',
            headerName: 'ИИН пользователя',
            width: 200
        },
        {
            field: 'fio',
            headerName: 'ФИО пользователя',
            width: 300
        },
        {
            field: 'phone',
            headerName: 'Телефон пользователя',
            width: 300
        },
    ];

    const tabs = {
        created: {id: 1, label: `Новые`},
        approved: {id: 2, label: `Ожидание подтверждения`},
        closed: {id: 3, label: `Зарегистрированные`},
        denied: {id: 10, label: `Отклонено`}
    };

    const handleRowClick: GridEventListener<'rowClick'> = (
        params,
    ) => {
        openAppeal(params.row.id);
    };

    return (
        <Box className={'appeals'}>
            <Stack sx={{mb: 2}} flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                <Typography sx={{mt: 3}} variant={'h5'}><b>Список заявок</b></Typography>
            </Stack>

            <InputBase
                type={'text'}
                onChange={(e) => {setFilter(e.target.value)}}
                value={filter}
                placeholder={'Поиск'}
                sx={{mt: 1}}
            />

            <Tabs
                value={tab}
                onChange={(e, newValue) => setTab(newValue)}
                variant="scrollable"
                scrollButtons={false}
                sx={{ml: 2}}
            >
                {Object.values(tabs).map(tab => <Tab label={tab.label} key={tab.id} value={tab.id}/>)}
            </Tabs>
            <CustomDataGrid
                rows={rows}
                onRowClick={handleRowClick}
                columns={columns}
                loading={loading}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                autoHeight={true}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
                disableColumnMenu={true}
                disableColumnFilter={true}
            />
        </Box>
    );
};

export default AppealsGrid;
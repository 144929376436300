import {BACKEND_URL} from '../../global/actions/apiVariables';
import axiosInstance from "../../global/actions/main.api";

export const apiGetAllGroups = () => {
    return axiosInstance.get(`${BACKEND_URL}/groups/all`);
}

export const apiGetSchoolsStatistics = () => {
    return axiosInstance.get(`${BACKEND_URL}/statistic/school`);
}

export const apiGetGroupsStatistics = () => {
    return axiosInstance.get(`${BACKEND_URL}/statistic/group`);
}

export const apiGetStudentsStatistics = () => {
    return axiosInstance.get(`${BACKEND_URL}/statistic/student`);
}
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { useUserStore } from '../../global/store/UserStore';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { useNavigate } from 'react-router-dom';
import {apiGetStudentsStatistics} from '../actions/api';
import { DirectionsCar, ExpandMoreOutlined } from '@mui/icons-material';
import useLoadingHook from "../../global/hooks/UseLoadingHook";

const regionsArray = [
  "Астана", "Алматы", "Шымкент",
  "Абайская область", "Акмолинская область", "Актюбинская область",
  "Алматинская область", "Атырауская область", "Восточно-Казахстанская область",
  "Жамбылская область", "Жетысуская область", "Западно-Казахстанская область",
  "Карагандинская область", "Костанайская область", "Кызылординская область",
  "Мангистауская область", "Павлодарская область", "Северо-Казахстанская область",
  "Туркестанская область", "Улытауская область"
]

const StudentsStatistic = () => {

  const [statisticsResponse, statisticsLoading, statisticsError, fetchStatistics] = useLoadingHook(apiGetStudentsStatistics);

  const isAdmin = useUserStore((state) => state.isAdmin);
  const currentUser = useUserStore((state) => state);
  const snackbar = useSnackbarStore((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      snackbar.errorMessage('У вас нет прав доступа к данной странице');
      navigate('/');
    } else {
      fetchStatistics();
    }
  }, []);

  useEffect(() => {
    if (statisticsError) {
      snackbar.errorMessage(statisticsError.response.data.message ? statisticsError.response.data.message : 'Произошла непредвиденная ошибка');
    }
  }, [statisticsResponse, statisticsError]);

  return (
    <Accordion sx={{mt: 2}}>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
      >
        <Stack flexDirection="row" alignItems="center" sx={{mt: 1, mb: 1}}>
          <Avatar
            sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2, width: 50, height: 50}}
          >
            <DirectionsCar fontSize="medium"/>
          </Avatar>
          <Stack>
            <Typography variant="h5">Статистика по студентам</Typography>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {statisticsLoading && <CircularProgress />}

        {statisticsResponse && (<>
          <Box flexDirection={'row'}>
            <Typography variant={'h6'}>Всего зарегистрировано студентов: <b>{statisticsResponse.total}</b></Typography>
          </Box>
          <Divider sx={{my: 1}}/>
          <Box flexDirection={'column'}>
            <Typography variant={'h6'}>
              Распределение по категориям:
            </Typography>
            {statisticsResponse.categories.map((categoryStats: any) => {
              return (
                <Typography fontSize={'14px'} key={`${categoryStats.category}-student`}>Категория "{categoryStats.category}": {categoryStats.count}</Typography>
              )
            })}
          </Box>
          <Divider sx={{my: 1}}/>
          <Box flexDirection={'column'}>
            <Typography variant={'h6'}>
              Распределение по регионам:
            </Typography>
            {statisticsResponse.regions.map((regionStats: any) => {
              return (
                <Typography fontSize={'14px'} key={`${regionStats.region}-student`}>{regionStats.region}: {regionStats.count}</Typography>
              )
            })}
          </Box>
        </>)}
      </AccordionDetails>
    </Accordion>
  );
};

export default StudentsStatistic;

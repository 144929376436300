import React, { useEffect, useState } from 'react';
import {DataGrid, GridColDef, GridRenderCellParams, GridRowModel} from "@mui/x-data-grid";
import {
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog, DialogContent,
    DialogTitle,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {formatDateTime, getName} from "../../../global/helpers/text";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import { useUserStore } from '../../../global/store/UserStore';
import GradesComponent from "./StudentGrades";
import useLoadingHook from '../../../global/hooks/UseLoadingHook';
import { getClassesByGroupId, getPracticeHoursByGroupId, getTheoryHoursByGroupId } from '../../actions/api';
import { getDayDateTime } from '../../../global/helpers/formatters';
import SchoolIcon from '@mui/icons-material/School';
import {Delete, DirectionsCar, Person} from '@mui/icons-material';
import SubmitClassModal from './SubmitClassModal';
import DeleteClassModal from "./DeleteClassModal";
import CustomDataGrid from "../../../global/components/CustomDataGrid";

interface ClassesGridProps {
  groupInfo: any,
  fetchGroupInfo: () => void
}

const ClassesGrid = ({groupInfo, fetchGroupInfo} : ClassesGridProps) => {

  const snackbar = useSnackbarStore((state) => state);
  const isAdmin = useUserStore((state) => state.isAdmin);
  const [classesList, classesLoading, , fetchClasses] = useLoadingHook<any[]>(getClassesByGroupId);
  const [theoryList, theoryLoading, , fetchTheory] = useLoadingHook(getTheoryHoursByGroupId);
  const [practiceList, practiceLoading, , fetchPractice] = useLoadingHook(getPracticeHoursByGroupId);

  const [submitOpen, setSubmitOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const [classInfo, setClassInfo] = useState<any>(null);

    useEffect(() => {
        if (groupInfo.id) {
          fetchClasses(Number(groupInfo.id));
          fetchTheory(Number(groupInfo.id));
          fetchPractice(Number(groupInfo.id));
        } else {
          snackbar.errorMessage('Отсутствует информация о группе')
        }
    }, [groupInfo]);

    useEffect(() => {
        if (classInfo) {
            console.warn(classInfo);
            setDeleteDialogOpen(true);
        }
    }, [classInfo]);

  if (classesLoading || theoryLoading || practiceLoading) {
    return (
      <Stack flexDirection={'row'} justifyContent={'center'}>
        <CircularProgress />
      </Stack>
    )
  };

  if (groupInfo.status === 1) {
    return (
      <Box>
        <Typography variant={"h4"}>
          Группа должна начать обучение
        </Typography>
      </Box>
    )
  }

  const columns: GridColDef[] = [
    {field: 'empty', headerName: '', headerClassName: 'datagrid--header', maxWidth: 10, width: 10, sortable: false},
    {
          field: 'delete',
          headerName: 'Удаление',
          width: 100,
          sortable: false,
          renderCell: (params: GridRenderCellParams<any>) => {
              return (
                  <Stack
                    flexDirection={'row'}
                    justifyContent={'center'}
                    sx={{
                        width: '100%'
                    }}
                  >
                      <Button
                        onClick={() => {handleDeleteDialogOpen(params.row.id)}}
                        disabled={groupInfo.status !== 2}
                      >
                          <Delete color={'error'}/>
                      </Button>
                  </Stack>
              )
          }
      },
    {
      field: 'start_date_time',
      headerName: 'Дата занятия',
      width: 170,
      sortable: true,
      renderCell: ({row}: GridRenderCellParams<any>) => getDayDateTime(row.start_date_time).date,
    },
    {
      field: 'day',
      headerName: 'День недели',
      width: 150,
      sortable: true,
      renderCell: ({row}: GridRenderCellParams<any>) => getDayDateTime(row.start_date_time).day,
    },
    {
      field: 'time',
      headerName: 'Время занятия',
      width: 150,
      sortable: true,
      renderCell: ({row}: GridRenderCellParams<any>) => `${getDayDateTime(row.start_date_time).time} - ${getDayDateTime(row.end_date_time).time}`,
    },
    {
      field: 'type',
      headerName: 'Тип занятия',
      width: 170,
      sortable: true,
      renderCell: ({row}: GridRenderCellParams<any>) => row.type === 'theory' ? 'Теория' : 'Практика',
    },
    {
      field: 'section',
      headerName: 'Раздел обучения',
      width: 250,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div style={{
          display: 'flex',
          alignItems: 'center', // Вертикальное центрирование
          height: '100%',
          whiteSpace: 'normal',
          lineHeight: 'normal',
          overflow: 'hidden',
          padding: '10px 0'
        }}>
          {params.row.section.title_ru}
        </div>
      ),
    },
    {
      field: 'topic',
      headerName: 'Тема обучения',
      width: 250,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div style={{
          display: 'flex',
          alignItems: 'center', // Вертикальное центрирование
          height: '100%',
          whiteSpace: 'normal',
          lineHeight: 'normal',
          overflow: 'hidden',
          padding: '10px 0'
        }}>
          {params.row?.topic?.title_ru ? params.row.topic.title_ru : 'Отсутствует'}
        </div>
      ),
    },
    {
      field: 'teacher', headerName: 'Преподаватель', minWidth: 350, sortable: false,
      renderCell: ({row}) => {

        if (!row.teacher) {
          return (
            <div style={{
              display: 'flex',
              alignItems: 'center', // Вертикальное центрирование
              height: '100%',
              whiteSpace: 'normal',
              lineHeight: 'normal',
              overflow: 'hidden',
              padding: '10px 0'
            }}>
              {'Отсутствует'}
            </div>
          )
        }

        const name = getName(row.teacher);
        return <React.Fragment>
          <Avatar
            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
          >
            {name.partialName}
          </Avatar>
          {name.fullName}
        </React.Fragment>;
      }
    },
    {
      field: 'master', headerName: 'Мастер пр. об.', minWidth: 350, sortable: false,
      renderCell: ({row}) => {

        if (!row.master) {
          return (
            <div style={{
              display: 'flex',
              alignItems: 'center', // Вертикальное центрирование
              height: '100%',
              whiteSpace: 'normal',
              lineHeight: 'normal',
              overflow: 'hidden',
              padding: '10px 0'
            }}>
              {'Отсутствует'}
            </div>
          )
        }

        const name = getName(row.master);
        return <React.Fragment>
          <Avatar
            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
          >
            {name.partialName}
          </Avatar>
          {name.fullName}
        </React.Fragment>;
      }
    },
    {
      field: 'driver', headerName: 'Мастер об. вожд.', minWidth: 350, sortable: false,
      renderCell: ({row}) => {

        if (!row.driver) {
          return (
            <div style={{
              display: 'flex',
              alignItems: 'center', // Вертикальное центрирование
              height: '100%',
              whiteSpace: 'normal',
              lineHeight: 'normal',
              overflow: 'hidden',
              padding: '10px 0'
            }}>
              {'Отсутствует'}
            </div>
          )
        }

        const name = getName(row.driver);
        return <React.Fragment>
          <Avatar
            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
          >
            {name.partialName}
          </Avatar>
          {name.fullName}
        </React.Fragment>;
      }
    },
    {
      field: 'cabinet', headerName: 'ID кабинета', minWidth: 350, sortable: false,
      renderCell: ({row}) => {

        if (!row.cabinet) {
          return (
            <div style={{
              display: 'flex',
              alignItems: 'center', // Вертикальное центрирование
              height: '100%',
              whiteSpace: 'normal',
              lineHeight: 'normal',
              overflow: 'hidden',
              padding: '10px 0'
            }}>
              {'Отсутствует'}
            </div>
          )
        }

        return <React.Fragment>
          <Avatar
            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
          >
            <SchoolIcon />
          </Avatar>
          #{row.cabinet.id}, {row.cabinet.address}
        </React.Fragment>;
      }
    },
    {
      field: 'car', headerName: 'Транспортное средство', minWidth: 250, sortable: false,
      renderCell: ({row}) => {

        if (!row.car) {
          return (
            <div style={{
              display: 'flex',
              alignItems: 'center', // Вертикальное центрирование
              height: '100%',
              whiteSpace: 'normal',
              lineHeight: 'normal',
              overflow: 'hidden',
              padding: '10px 0'
            }}>
              {'Отсутствует'}
            </div>
          )
        }

        return <React.Fragment>
          <Avatar
            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
          >
            <DirectionsCar />
          </Avatar>
          {row.car.model} {row.car.plate_number}
        </React.Fragment>;
      }
    },
      {
          field: 'student', headerName: 'Студент', minWidth: 350, sortable: false,
          renderCell: ({row}) => {

              if (!row.student) {
                  return (
                      <div style={{
                          display: 'flex',
                          alignItems: 'center', // Вертикальное центрирование
                          height: '100%',
                          whiteSpace: 'normal',
                          lineHeight: 'normal',
                          overflow: 'hidden',
                          padding: '10px 0'
                      }}>
                          {'Отсутствует'}
                      </div>
                  )
              }

              const name = getName(row.student);
              return <React.Fragment>
                  <Avatar
                      sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                  >
                      {name.partialName}
                  </Avatar>
                  {name.fullName}
              </React.Fragment>;
          }
      },
  ];

  const handleSubmit = (st: boolean) => {
    setSubmitOpen(st);
  };

  const handleDeleteDialogOpen = (classId: string) => {
      if (!classesList) {
          return;
      }

      for (let groupClass of classesList) {
          if (groupClass.id == classId) {
              setClassInfo(groupClass);
          }
      }
  };

  return (
    <Box flexDirection={'column'} display={'flex'} gap={1}>

      <Stack flexDirection={'row'} justifyContent={'center'} sx={{m: 1}}>
        <Button
          variant={'contained'}
          onClick={() => {handleSubmit(true)}}
        >
          Запланировать новое занятие
        </Button>
      </Stack>

      <Typography variant={'h6'}>
        Информация по проведенным учебным часам
      </Typography>

      <Box>
        {theoryList?.map((theorySection: any) => {
          return (
            <Stack
              sx={{
                bgcolor: 'white',
                p: 3,
                borderRadius: '12px',
                border: `1px solid ${theorySection.current_hours === theorySection.max_hours ? 'green' : 'red'}`,
                display: 'flex',
                mb: 1
              }}
              key={theorySection.section.id}
            >
              <Typography><b>{theorySection.section.title_ru}:</b> {theorySection.current_hours}/{theorySection.max_hours} академических часов</Typography>
            </Stack>
          )
        })}
      </Box>

      <Box>
        {practiceList?.map((studentInfo: any) => {
          return (
            <Stack
              sx={{
                bgcolor: 'white',
                p: 3,
                borderRadius: '12px',
                border: `1px solid ${studentInfo.current_hours === studentInfo.max_hours ? 'green' : 'red'}`,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                mb: 1
              }}
              key={studentInfo.id}
            >
              <Avatar
                sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
              >
                <Person />
              </Avatar>
              <Typography>
                <b>{studentInfo.student.last_name} {studentInfo.student.first_name}: </b>
                {studentInfo.current_hours}/{studentInfo.max_hours} часов практического вождения
              </Typography>
            </Stack>
          )
        })}
      </Box>

      <Typography variant={'h6'}>
        Информация по проведенным и планируемым занятиям
      </Typography>

      <CustomDataGrid
        columns={columns}
        rows={classesList ? classesList : []}
        loading={false}
        pageSizeOptions={[30]}
        disableRowSelectionOnClick={true}
        getRowHeight={() => 'auto'}
        autoHeight={true}
        disableColumnMenu={true}
        disableColumnFilter={true}
      />

      <SubmitClassModal
        groupInfo={groupInfo}
        open={submitOpen}
        setOpen={handleSubmit}
        fetchGroupInfo={fetchGroupInfo}
        fetchClasses={fetchClasses}
      />

        <DeleteClassModal
            classInfo={classInfo}
            open={deleteDialogOpen}
            setOpen={(st: boolean) => setDeleteDialogOpen(st)}
            fetchGroupInfo={fetchGroupInfo}
            fetchClasses={fetchClasses}
        />
    </Box>
  );
};

export default ClassesGrid;
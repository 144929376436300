import React, {useEffect, useState} from 'react';
import {Avatar, Box, Button, Chip, CircularProgress, Divider, Stack, Typography} from '@mui/material';
import {useNavigate} from "react-router-dom";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {useUserStore} from "../../global/store/UserStore";
import {
  StudentInfoType,
  TheoryExamType,
  TransactionChipStatus, TransactionStatus,
  TransactionType,
  TrialExamType
} from "../../global/actions/types.api";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {apiFindStudentsByIIN} from "../../GovPage/actions/api";
import {apiGetUserTransactions} from "../../Payments/actions/api";
import {apiGetUserTheoryExams, apiGetUserTrialExams} from "../../TheoryExam/actions/api";
import {getName} from "../../global/helpers/text";
import StartTrialExamModal from "../../Kursant/components/StartTrialExamModal";
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import StartTheoryExamModal from "../../Kursant/components/StartTheoryExamModal";
import StudentGroupCard from "../components/StudentGroupCard";
import {apiGetFeatureEnabled} from "../../global/actions/main.api";

// TODO - разделить личные кабинеты администратора, менеджеров школ и студентов по разным страницам.

function StudentMainPage() {

  const navigate = useNavigate();
  const snackbar = useSnackbarStore((state) => state);
  const currentUser = useUserStore((state) => state);

  // Добавить useEffect, который на основе currentUser определит - студент или нет, и перенаправит/оставит в зависимости от проверки.

  const [currentStudentInfo, setCurrentStudentInfo] = useState<StudentInfoType | null>(null);
  const [startExamModalOpen, setStartExamModalOpen] = useState<boolean>(false);
  const [startTrialExamModalOpen, setStartTrialExamModalOpen] = useState<boolean>(false);
  const [hasGeneratedPassword, setHasGeneratedPassword] = useState<boolean>(false);

  const [attachmentModuleInfoResponse, attachmentModuleInfoLoading, attachmentModuleInfoError, requestAttachmentModuleInfo] = useLoadingHook(apiGetFeatureEnabled);
  const [trialModuleInfoResponse, trialModuleInfoLoading, trialModuleInfoError, requestTrialModuleInfo] = useLoadingHook(apiGetFeatureEnabled);
  const [studentsInfo, studentsInfoLoading, studentsInfoError, getStudentsInfo] = useLoadingHook<StudentInfoType[]>(apiFindStudentsByIIN);
  const [transactionsInfo, transactionsInfoLoading, transactionsInfoError, getTransactionsInfo] = useLoadingHook<TransactionType[]>(apiGetUserTransactions);
  const [theoryExamsInfo, theoryExamsInfoLoading, theoryExamsInfoError, getTheoryExamsInfo] = useLoadingHook<TheoryExamType[]>(apiGetUserTheoryExams);
  const [trialExamsInfo, trialExamsInfoLoading, trialExamsInfoError, getTrialExamsInfo] = useLoadingHook<TrialExamType[]>(apiGetUserTrialExams);

  const [showAllTheoryExams, setShowAllTheoryExams] = useState<boolean>(false);
  const [displayedTheoryExams, setDisplayedTheoryExams] = useState<TheoryExamType[]>([]);

  useEffect(() => {
    if (theoryExamsInfo) {
      showAllTheoryExams ? setDisplayedTheoryExams(theoryExamsInfo) : setDisplayedTheoryExams(theoryExamsInfo?.slice(0,5));
    }
  }, [showAllTheoryExams, theoryExamsInfo]);

  const name = getName(currentUser);

  useEffect(() => {
    getStudentsInfo(currentUser.iin);
    getTransactionsInfo(currentUser.id);
    getTheoryExamsInfo(currentUser.id);
    getTrialExamsInfo(currentUser.id);
    requestAttachmentModuleInfo('paid_student_attachments');
    requestTrialModuleInfo('trial_exams');
  }, []);

  useEffect(() => {
    if (studentsInfo) {
      studentsInfo.forEach((studentInfo) => {
        if (studentInfo.generated_user_password) {
          setHasGeneratedPassword(true);
        }
      })
    }
  }, [studentsInfo]);

  const handleStartExam = (studentId: number) => {
    studentsInfo?.map((student) => {
      if (student.id === studentId) {
        setCurrentStudentInfo(student);
        setStartExamModalOpen(true);
      }
    })
  };

  return (
    <Box className="App"
         sx={{
           height: '100%',
           display: 'flex',
           flexDirection: 'column'
         }}
    >
      <PageHeader />
      <Box sx={{...wrapperSx}}>
        {!currentUser.iin && <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
            <Typography sx={{mt: 3}} variant={'h5'}><b>Информационная система</b></Typography>
            <Typography variant={'h6'}>Для учета групп учебной организации по подготовке водителей транспортных
                средств Республики Казахстан</Typography>
        </Stack>}

        {currentUser.iin && <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
            <Typography sx={{mt: 3}} variant={'h5'}>Добро пожаловать в систему, {currentUser.last_name} {currentUser.first_name}!</Typography>
        </Stack>}

        <Stack direction={'column'} sx={{alignItems: 'center', mt: 5}}>
          <Stack
            direction={'column'}
            width={'100%'}
            sx={{
              mt: '38px',
              p: '52px',
              borderRadius: '24px',
              background: '#F5F5F5',
            }}
          >
            {hasGeneratedPassword && (<Stack
              flexDirection={'column'}
              sx={{
                background: '#ffeeee',
                borderRadius: '10px',
                p: 5,
                mb: 5
              }}
              alignItems={'center'}
            >
              <Typography>
                Уважаемый пользователь, на Вашей учетной записи стоит временный пароль, сгенерированный вашей
                автошколой. В целях информационной безопасности, просим Вас сменить пароль.
              </Typography>
            </Stack>)}
            <Stack flexDirection={'row'} sx={{mb: '38px'}}>
              <Avatar
                sx={{width: '57px', height: '57px', mr: 1.5, fontSize: 24, color: '#99999F', bgcolor: '#D9E8FF'}}
              >
                {name.partialName}
              </Avatar>

              <Stack flexDirection={'column'} justifyContent={'flex-start'}>
                <Typography sx={{fontSize: '16px'}}>{name.fullName}</Typography>

                <Typography sx={{mt: 'auto', fontSize: '12px'}}>Ваша роль: Курсант</Typography>
                <Typography sx={{fontSize: '12px'}}><b>ID:{' '}</b>{currentUser.id}</Typography>
              </Stack>


              <Button
                sx={{p: 2, maxWidth: '250px', ml: 'auto'}}
                variant={'outlined'}
                onClick={() => {navigate(`/change-password/${currentUser.id}`)}}
                color={'info'}
              >
                Сменить пароль
              </Button>
            </Stack>

            {trialModuleInfoResponse?.is_enabled && <Button
              variant={'contained'}
              sx={{
                width: 'fit-content',
                mb: '32px'
              }}
              onClick={() => {
                setStartTrialExamModalOpen(true)
              }}
            >
              Пройти пробное тестирование
            </Button>}
            <Divider />
            <Typography
              variant={'h5'}
              sx={{
                mt: '32px'
              }}
            >
              Информация по вашему обучению в автошколах:
            </Typography>
            {(studentsInfoLoading || attachmentModuleInfoLoading) && (<Stack
              display={'flex'}
              flexDirection={'row'}
              flexWrap={'wrap'}
              sx={{
                py: '32px'
              }}
            >
              <CircularProgress />
            </Stack>)}
            {studentsInfo && attachmentModuleInfoResponse && (<Stack
              display={'flex'}
              flexDirection={'row'}
              flexWrap={'wrap'}
              sx={{
                py: '32px',
                gap: '24px'
              }}
            >
              {studentsInfo.length === 0 && <Chip sx={{p: 2}} label={<Typography variant={'h6'}>Нет данных</Typography>} />}
              {studentsInfo.length > 0 && studentsInfo.map((studentInfo) => {
                return (
                  <StudentGroupCard
                    studentInfo={studentInfo}
                    handleStartExam={() => {handleStartExam(studentInfo.id)}}
                    key={studentInfo.id}
                    attachmentModule={attachmentModuleInfoResponse}
                  />
                )
              })}
            </Stack>)}
            <Divider />
            <Typography
              variant={'h5'}
              sx={{
                mt: '32px'
              }}
            >
              История результатов внутришкольных экзаменов:
            </Typography>

            <Stack
              flexDirection={'row'}
              flexWrap={'wrap'}
              sx={{
                py: '32px',
                gap: '24px'
              }}
            >
              {theoryExamsInfo?.length === 0 && <Chip sx={{p: 2}} label={<Typography variant={'h6'}>Нет данных</Typography>} />}
              {theoryExamsInfoLoading && (<Stack
                display={'flex'}
                flexDirection={'row'}
                flexWrap={'wrap'}
                sx={{
                  py: '32px'
                }}
              >
                <CircularProgress />
              </Stack>)}
              {displayedTheoryExams?.map((theoryExam) => {
                return (
                  <Stack
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{
                      width: '300px',
                      height: 'auto',
                      minHeight: '150px',
                      borderRadius: '16px',
                      backgroundColor: '#FFFFFF',
                      py: '20px',
                      px: '16px',
                      gap: '16px'
                    }}
                    key={theoryExam.id}
                  >
                    <Typography
                      fontSize={'14px'}
                    >
                      Внутришкольный экзамен по теории
                    </Typography>
                    <Typography
                      fontSize={'14px'}
                      color={'gray'}
                      sx={{
                        mt: '-16px'
                      }}
                    >
                      #{theoryExam.id}
                    </Typography>
                    <Chip
                      label={`${theoryExam.category_id} категория`}
                      sx={{
                        width: 'fit-content'
                      }}
                    />
                    {theoryExam.ended_at && (
                      <Typography>
                        Правильные ответы: {theoryExam.correct_answers}/{theoryExam.total_questions}
                      </Typography>
                    )}
                    <Button
                      variant={'contained'}
                      onClick={() => {theoryExam.ended_at ? navigate(`/theory-exams/results/${theoryExam.id}`) : navigate(`/theory-exams/${theoryExam.id}`)}}
                      sx={{
                        width: 'fit-content',
                        alignSelf: 'end'
                      }}
                    >
                      {theoryExam.ended_at && 'Результаты'}
                      {!theoryExam.ended_at && 'Возобновить'}
                    </Button>
                  </Stack>
                )
              })}
              {theoryExamsInfo && theoryExamsInfo.length > 5 && (
                <Stack
                  display={'flex'}
                  flexDirection={'column'}
                  sx={{
                    width: '300px',
                    height: 'auto',
                    minHeight: '150px',
                    borderRadius: '16px',
                    backgroundColor: '#FFFFFF',
                    py: '20px',
                    px: '16px',
                    gap: '16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowAllTheoryExams((prev) => !prev)}
                >
                  <Typography
                    fontSize={'14px'}
                    color={'primary'}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    {showAllTheoryExams ? 'Свернуть список' : 'Развернуть список'}
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Divider />
            <Typography
              variant={'h5'}
              sx={{
                mt: '32px'
              }}
            >
              История результатов пробных теоретических экзаменов:
            </Typography>

            <Stack
              flexDirection={'row'}
              flexWrap={'wrap'}
              sx={{
                py: '32px',
                gap: '24px'
              }}
            >
              {trialExamsInfo?.length === 0 && <Chip sx={{p: 2}} label={<Typography variant={'h6'}>Нет данных</Typography>} />}
              {trialExamsInfoLoading && (<Stack
                display={'flex'}
                flexDirection={'row'}
                flexWrap={'wrap'}
                sx={{
                  py: '32px'
                }}
              >
                <CircularProgress />
              </Stack>)}
              {trialExamsInfo?.map((trialExam) => {
                return (
                  <Stack
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{
                      width: '300px',
                      height: 'auto',
                      minHeight: '150px',
                      borderRadius: '16px',
                      backgroundColor: '#FFFFFF',
                      py: '20px',
                      px: '16px',
                      gap: '16px'
                    }}
                    key={trialExam.id}
                  >
                    <Typography
                      fontSize={'14px'}
                    >
                      Пробный экзамен по теории
                    </Typography>
                    <Typography
                      fontSize={'14px'}
                      color={'gray'}
                      sx={{
                        mt: '-16px'
                      }}
                    >
                      #{trialExam.id}
                    </Typography>
                    <Chip
                      label={`${trialExam.category_id} категория`}
                      sx={{
                        width: 'fit-content'
                      }}
                    />
                    {trialExam.ended_at && (
                      <Typography>
                        Правильные ответы: {trialExam.correct_answers}/{trialExam.total_questions}
                      </Typography>
                    )}
                    <Button
                      variant={'contained'}
                      onClick={() => {trialExam.ended_at ? navigate(`/trial-exams/results/${trialExam.id}`) : navigate(`/trial-exams/${trialExam.id}`)}}
                      sx={{
                        width: 'fit-content',
                        alignSelf: 'end'
                      }}
                    >
                      {trialExam.ended_at && 'Результаты'}
                      {!trialExam.ended_at && 'Возобновить'}
                    </Button>
                  </Stack>
                )
              })}
            </Stack>
            <Divider
              sx={{
                mt: '32px'
              }}
            />
            <Typography
              variant={'h5'}
              sx={{
                mt: '32px'
              }}
            >
              История транзакций:
            </Typography>

            <Stack
              flexDirection={'row'}
              flexWrap={'wrap'}
              sx={{
                py: '32px',
                gap: '24px'
              }}
            >
              {transactionsInfo?.length === 0 && <Chip sx={{p: 2}} label={<Typography variant={'h6'}>Нет данных</Typography>} />}
              {transactionsInfoLoading && (<Stack
                display={'flex'}
                flexDirection={'row'}
                flexWrap={'wrap'}
                sx={{
                  py: '32px'
                }}
              >
                <CircularProgress />
              </Stack>)}
              {transactionsInfo?.map((transaction) => {
                return (
                  <Stack
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{
                      width: '300px',
                      height: 'auto',
                      minHeight: '150px',
                      borderRadius: '16px',
                      backgroundColor: '#FFFFFF',
                      py: '20px',
                      px: '16px',
                      gap: '16px'
                    }}
                    key={transaction.id}
                  >
                    <Typography
                      fontSize={'14px'}
                    >
                      Транзакция #{transaction.id}
                    </Typography>
                    <Typography>
                      Сумма: {transaction.price} ₸
                    </Typography>
                    <Chip
                      label={TransactionChipStatus[transaction.status]}
                      sx={{
                        width: 'fit-content'
                      }}
                      color={transaction.status === TransactionStatus.Paid ? 'success' : 'default'}
                    />
                    <Button
                      variant={'contained'}
                      onClick={() => {navigate(`/transactions/${transaction.id}`)}}
                      sx={{
                        width: 'fit-content',
                        alignSelf: 'end'
                      }}
                    >
                      Подробнее
                    </Button>
                  </Stack>
                )
              })}
            </Stack>

            <StartTheoryExamModal
              studentInfo={currentStudentInfo}
              open={startExamModalOpen}
              onClose={() => {setStartExamModalOpen(false)}}
            />
            <StartTrialExamModal
              open={startTrialExamModalOpen}
              onClose={() => {setStartTrialExamModalOpen(false)}}
            />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export default StudentMainPage;

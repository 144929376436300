import React, {useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider, FormControl, InputLabel,
    MenuItem,
    Select, SelectChangeEvent,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {StudentInfoType} from "../../global/actions/types.api";
import LoadingButton from "../../global/components/LoadingButton";
import InfoIcon from "@mui/icons-material/Info";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {
    apiGetUserTrialTries,
    apiRequestExam,
    apiRequestTrialExam,
    CreateTheoryExamResponse, CreateTrialExamResponse
} from "../../TheoryExam/actions/api";
import {useNavigate} from "react-router-dom";
import {useUserStore} from "../../global/store/UserStore";

interface ModalProps {
    open: boolean,
    onClose: () => void,
}

const categoriesArray = ['A', 'A1', 'B', 'B1', 'BC1', 'BE', 'C', 'C1', 'C1E', 'CE', 'D', 'D1', 'D1E', 'DE', 'Tb', 'Tm'];

const StartTrialExamModal = ({open, onClose} : ModalProps) => {

    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);

    const [userTriesInfo, userTriesLoading, userTriesError, requestUserTries] = useLoadingHook(apiGetUserTrialTries);
    const [requestExamResponse, requestExamLoading, requestExamError, requestExamInfo] = useLoadingHook<CreateTrialExamResponse>(apiRequestTrialExam);

    const [categoryId, setCategoryId] = useState<string>('A');

    const handleRequestExam = () => {
        if (currentUser) {
            requestExamInfo({
                user_id: currentUser.id,
                category_id: categoryId
            });
        }
    };

    const handleNavigateToPurchasePage = () => {
        navigate('/purchase', {
            state: {
                purchaseType: 'theory_exams'
            }
        })
    }

    useEffect(() => {
        requestUserTries(Number(currentUser.id));
    }, []);

    useEffect(() => {
        if (requestExamResponse) {
            console.warn(`request`)
            console.warn(requestExamResponse);
            snackbar.successMessage(requestExamResponse.message);
            navigate('/trial-exams/' + requestExamResponse.trial_exam_id);
        }

        if (requestExamError) {
            snackbar.errorMessage(requestExamError.response?.data?.message ? requestExamError.response?.data?.message : 'Произошла непредвиденная ошибка');
        }
    }, [requestExamResponse, requestExamError]);

    const handleCategoryChange = (e: SelectChangeEvent<string>) => {
        setCategoryId(e.target.value)
    }

    if (userTriesInfo) {
        return (
            <Dialog
                maxWidth={'xl'}
                fullWidth={true}
                open={open}
                onClose={onClose}
            >
                <DialogTitle>
                    Пробный экзамен по теории студента {currentUser.last_name} {currentUser.first_name}
                </DialogTitle>
                <DialogContent>
                    <Stack
                        flexDirection={'column'}
                        gap={'16px'}
                    >
                        <Stack>
                            <Typography variant={'h6'}>
                                Информация по попыткам сдачи экзаменов:
                            </Typography>
                            <Typography>
                                Количество доступных попыток - {userTriesInfo.paid_tries}
                            </Typography>
                            <Typography>
                                Количество использованных попыток - {(userTriesInfo?.used_tries ?? 0)}
                            </Typography>
                        </Stack>

                        <Stack>
                            <Typography>
                                Выберите учебную категорию:
                            </Typography>
                            <FormControl
                                sx={{
                                    mt: '16px'
                                }}
                            >
                                <InputLabel id={'type-label'}>Учебная категория</InputLabel>
                                <Select
                                    labelId={'category-label'}
                                    onChange={handleCategoryChange}
                                    name={'category'}
                                    label={'Учебная категория'}
                                    sx={{
                                        border: 'none',
                                    }}
                                    value={categoryId}
                                    required={true}
                                >
                                    {categoriesArray.map((category: any) => {
                                        return (
                                            <MenuItem
                                                value={category}
                                                key={category}
                                            >
                                                {category}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>

                        <Divider />

                        <Stack
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Stack
                                flexDirection={'row'}
                                gap={'16px'}
                                alignItems={'center'}
                            >
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        width: 'fit-content'
                                    }}
                                    loading={requestExamLoading}
                                    onClick={handleRequestExam}
                                >
                                    Начать прохождение экзамена
                                </LoadingButton>
                                <Tooltip title={'На прохождение экзамена дается 40 минут. Правильные ответы можно увидеть только по окончании экзамена. Если вы случайным образом закрыли страницу прохождения тестирования - вы можете вернуться на нее в личном кабинете, нажав на кнопку "Пройти пробное тестирование".'}>
                                    <InfoIcon sx={{color: 'gray'}}/>
                                </Tooltip>
                            </Stack>

                            <LoadingButton
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: 'fit-content'
                                }}
                                loading={requestExamLoading}
                                onClick={() => {handleNavigateToPurchasePage()}}
                            >
                                Получить больше попыток
                            </LoadingButton>
                        </Stack>

                    </Stack>
                </DialogContent>
            </Dialog>
        );
    } else {
        return (
            <></>
        );
    }
};

export default StartTrialExamModal;
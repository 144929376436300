import React, { useEffect, useState } from 'react';
import { useUserStore } from '../../global/store/UserStore';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import PageHeader from '../../global/components/PageHeader';
import { Avatar, Box, Button, CircularProgress, Stack, SxProps, Theme, Typography } from '@mui/material';
import { getUserById } from '../actions/api';
import UsersSchools from '../components/UsersSchools';

const titleSx: SxProps<Theme> = {
    width: 120,
    fontWeight: 500,
    color: '#5E5E5E',
    fontSize: 17
};

const valueSx: SxProps<Theme> = {
    fontSize: 17
};

const UserPage = () => {
    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const navigate = useNavigate();
    const {id} = useParams();
    const [userInfo, setUserInfo] = useState<any>({
        id: id,
        first_name: '',
        last_name: '',
        patronymic: '',
        phone: '',
        email: ''
    });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const fetchUserInfo = () => {
        if (isAdmin || (Number(currentUser.id) === Number(id))) {
            setLoading(true);
            getUserById(Number(id))
                .then((res) => {
                    setUserInfo(res.data);
                    setLoading(false);
                })
                .catch((err) => {
                    snackbar.errorMessage(err.response.data.message);
                })
        } else {
            snackbar.errorMessage(`У вас нет доступа к информации данного пользователя`);
            navigate('/')
        }
    }

    return (
        <Box sx={{pt: 1, pr: 5, pl: 5, pb: 5}}>
            <PageHeader/>

            {loading && <CircularProgress />}

            {!loading && (
                <>
                    <Stack flexDirection="row" alignItems="center" sx={{mt: 1, mb: 3}}>
                        <Avatar
                            sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2, width: 50, height: 50}}
                        >
                        </Avatar>
                        <Stack>
                            <Typography variant="h5" color="dimgray">Специалист ID:{userInfo.id}</Typography>
                            <Typography variant="h4" sx={{mt: 0.3}}>{userInfo.last_name} {userInfo.first_name}  {userInfo.patronymic ? userInfo.patronymic : ''}</Typography>
                        </Stack>
                    </Stack>

                    <Stack sx={{mt: 0}}>
                        <Stack flexDirection="row" gap={1} sx={{mt: 0.5}} alignItems={'center'}>
                            <Typography sx={titleSx}>ИИН</Typography>
                            <Typography sx={valueSx}>{userInfo?.iin}</Typography>
                        </Stack>
                        <Stack flexDirection="row" gap={1} sx={{mt: 0.5}} alignItems={'center'}>
                            <Typography sx={titleSx}>Фамилия</Typography>
                            <Typography sx={valueSx}>{userInfo.last_name}</Typography>
                        </Stack>
                        <Stack flexDirection="row" gap={1} sx={{mt: 0.5}} alignItems={'center'}>
                            <Typography sx={titleSx}>Имя</Typography>
                            <Typography sx={valueSx}>{userInfo.first_name}</Typography>
                        </Stack>
                        <Stack flexDirection="row" gap={1} sx={{mt: 0.5}} alignItems={'center'}>
                            <Typography sx={titleSx}>Отчество</Typography>
                            <Typography sx={valueSx}>{userInfo.patronymic ? userInfo.patronymic : 'Не указано'}</Typography>
                        </Stack>
                        <Stack flexDirection="row" gap={1} sx={{mt: 0.5}} alignItems={'center'}>
                            <Typography sx={titleSx}>Телефон</Typography>
                            <Typography sx={valueSx}>{userInfo.phone ? userInfo.phone : 'Не указано'}</Typography>
                        </Stack>
                        <Stack flexDirection="row" gap={1} sx={{mt: 0.5}} alignItems={'center'}>
                            <Typography sx={titleSx}>Электронная почта</Typography>
                            <Typography sx={valueSx}>{userInfo.email ? userInfo.email : 'Не указано'}</Typography>
                        </Stack>
                    </Stack>

                    <Stack gap={'16px'} sx={{mt: '16px'}}> 
                        {isAdmin &&  <Link to={`/change-userinfo/${userInfo.id}`}><Button variant={'outlined'}>Изменить данные пользователя</Button></Link>}
                        {isAdmin &&  <Link to={`/change-password/${userInfo.id}`}><Button variant={'outlined'}>Сменить пароль</Button></Link>}
                    </Stack>

                    <UsersSchools userId={userInfo.id} />
                </>
            )}
        </Box>
    );
};

export default UserPage;

import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Paper,
    Select, SelectChangeEvent,
    Stack,
    SxProps,
    Theme, Tooltip,
    Typography
} from "@mui/material";
import {GroupDto} from "../../actions/group.type";
import {useSchoolStore} from "../../../School/store/SchoolStore";
import {blue} from "@mui/material/colors";
import {
    attachDriverToGroup,
    detachDriverFromGroup,
} from "../../actions/api";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import {getActiveSchoolDrivers} from "../../../Employees/actions/api";
import ClearIcon from "@mui/icons-material/Clear";
import { useUserStore } from '../../../global/store/UserStore';
import {useTranslation} from "react-i18next";

const paperSx: SxProps<Theme> = {
    boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 6px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
    padding: 3,
    borderRadius: 4
};

interface DriversWindowProps {
    groupInfo: GroupDto;
    fetchGroupInfo: () => void;
}

interface DriversModalProps {
    groupInfo: GroupDto;
    fetchGroupInfo: () => void;
    open: boolean;
    onClose: () => void;
}

const DriversModal = ({groupInfo, fetchGroupInfo, open, onClose} : DriversModalProps) => {

    const {t} = useTranslation();

    const [driversList, setDriversList] = useState<any[]>([]);
    const [driverId, setDriverId] = useState<string>('');
    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);

    useEffect(() => {
        getActiveSchoolDrivers(Number(schoolInfo.id))
            .then((res) => {
                console.log(res);
                setDriversList(res.data);
            })
    }, []);

    const handleSelectChange = (e: SelectChangeEvent) => {
        setDriverId(e.target.value);
    }

    const handleAttach = () => {

        if (!groupInfo.id || !driverId) {
            snackbar.errorMessage(t('school_page.group_page.attributes_window.modal.required.driver'));
            return;
        }

        const driverDto = {
            group_id: groupInfo.id,
            driver_id: Number(driverId)
        }

        attachDriverToGroup(driverDto)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                fetchGroupInfo();
            })
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                {t('school_page.group_page.attributes_window.modal.add_dialog.driver')} <i><b>{groupInfo.title}</b></i>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Select
                        onChange={handleSelectChange}
                        value={driverId}
                    >
                        {driversList.map((teacher) => {
                            return (
                                <MenuItem value={teacher.id} key={teacher.id}>
                                    <Stack sx={{width: '100%'}} flexDirection="row" alignItems={'center'}>
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            {teacher.last_name[0]}{teacher.first_name[0]}
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack flexDirection="row" alignItems="center" justifyContent="space-between"
                                                   sx={{width: '100%'}}>
                                                <Typography>{teacher.last_name} {teacher.first_name}</Typography>
                                                <Typography variant="body2" color="gray">#{teacher.id}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            )
                        })}
                    </Select>

                    <Typography variant="body2" sx={{mt: 1}}>
                        {t('school_page.group_page.attributes_window.modal.reminder.driver')}
                    </Typography>

                    <Button onClick={handleAttach}>
                        Прикрепить мастера обучения вождению
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

const DriversWindow = ({groupInfo, fetchGroupInfo} : DriversWindowProps) => {

    const {t} = useTranslation();

    const isAdmin = useUserStore((state) => state.isAdmin);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const handleDetach = () => {
        const driverDto = {
            driver_id: groupInfo.driver.driver.id,
            group_id: groupInfo.id
        }

        detachDriverFromGroup(driverDto)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                fetchGroupInfo();
            })
    }


    return (
        <Box>
            <Paper sx={{...paperSx, mb: 5}}>

                <Typography variant="h4">{t('school_page.group_page.attributes_window.window.subtitle.driver')}</Typography>
                <Typography variant="subtitle1" sx={{mb: 1.5}} color="gray">
                    {t('school_page.group_page.attributes_window.window.this_group.driver')}
                </Typography>
                <Stack
                    flexDirection={'column'}
                    gap={'16px'}
                >
                    {groupInfo?.driver?.length === 0 && (
                        <Typography
                            fontSize={'14px'}
                            color={"gray"}
                        >
                            {t('school_page.group_page.attributes_window.window.no_data')}
                        </Typography>
                    )}

                    {groupInfo?.driver?.length > 0 && (
                        groupInfo?.driver?.map((driver: any) => {
                            return (
                                <>
                                    <Stack sx={{ml: 2, width: '400px', p: 2, borderRadius: '10px', background: '#F4F6F8'}} flexDirection="row" alignItems={'center'}>
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            {driver.driver.last_name[0]}{driver.driver.first_name[0]}
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack flexDirection="row" alignItems="center" justifyContent="start"
                                                   sx={{width: '100%'}}>
                                                <Typography>{driver.driver.last_name} {driver.driver.first_name}</Typography>
                                                <Typography variant="body2" color="gray" sx={{ml: 1}}>#{driver.driver.id}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </>
                            )
                        })
                    )}
                    <Button
                        onClick={() => {setIsDialogOpen(true)}}
                        sx={{
                            width: 'fit-content',
                            ml: '16px'
                        }}
                        variant={'contained'}
                    >
                        {t('school_page.group_page.attributes_window.modal.add_btn.driver')}
                    </Button>
                </Stack>
            </Paper>

            <DriversModal
                open={isDialogOpen}
                groupInfo={groupInfo}
                fetchGroupInfo={fetchGroupInfo}
                onClose={() => {setIsDialogOpen(false)}}
            />
        </Box>
    );
};

export default DriversWindow;
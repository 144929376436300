import React, {useEffect, useState} from 'react';
import {Stack, useTheme} from "@mui/material";
import TabSlice, {TabType} from "./TabSlice";
import {useTranslation} from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const LanguageSelector = () => {

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  const languageTabs: TabType[] = [
    {
      order: 0,
      type: 'ru',
      text: 'RU',
    },
    {
      order: 1,
      type: 'kz',
      text: 'KZ',
    }
  ];

  const { t, i18n } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<TabType>(i18n.language === 'ru' ? languageTabs[0] : languageTabs[1]);

  useEffect(() => {
    if (i18n.language) {
      if (i18n.language === 'ru') {
        setSelectedTab(languageTabs[0]);
      } else {
        setSelectedTab(languageTabs[1]);
      }
    }
  }, []);

  useEffect(() => {
    if (i18n.language) {
      if (i18n.language === 'ru') {
        setSelectedTab(languageTabs[0]);
      } else {
        setSelectedTab(languageTabs[1]);
      }
    }
  }, [i18n.language]);

  const handleLanguageChange = (tab: TabType) => {
    const language = tab.type;
    i18n.changeLanguage(language);
  }

  return (
    <Stack
      flexDirection={'row'}
      alignItems={'center'}
      sx={{
        background: '#1E9AF3',
        borderRadius: '32px',
        height: 'fit-content',
        width: 'fit-content'
      }}
    >
      <TabSlice
        tabs={languageTabs}
        onChange={handleLanguageChange}
        currentTab={selectedTab}
        width={isMobile ? 36 : 52}
        activeColor={'#fff'}
      />
    </Stack>
  );
};

export default LanguageSelector;
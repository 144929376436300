import React, {useEffect, useState} from 'react';
import {getGroupsBySchoolId} from "../actions/api";
import {useSchoolStore} from "../../School/store/SchoolStore";
import {useNavigate} from "react-router-dom";
import {Box, Button, Pagination, Stack, Typography} from '@mui/material';
import GroupsTable from "../components/GroupsList/GroupsTable";
import NewGroupDialog from "../components/GroupsList/NewGroupDialog";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import PageHeader from "../../global/components/PageHeader";
import { useUserStore } from '../../global/store/UserStore';
import {wrapperSx} from "../../global/helpers/globalstyles";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {useTranslation} from "react-i18next";
import {gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector} from "@mui/x-data-grid";
import {PaginationItem} from "@mui/lab";

export interface PaginationArgs {
    page: number,
    limit: number,
    offset: number,
    count: number
}

const GroupsList = () => {

    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const userInfo = useUserStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const navigate = useNavigate();

    const {t} = useTranslation();

    const userExceptions = ['ber1q@mail.ru', 'aliya-zhumagulova@inbox.ru', 'administrator4@kursant.kz', 'sup.autoonline@gmail.com']
    const emailCheck = isAdmin && !userExceptions.includes('d.dayana@kursant.kz')
    const [groupsList, groupsLoading, groupsError, fetchGroups] = useLoadingHook(getGroupsBySchoolId)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('');
    const [pagination, setPagination] = useState<PaginationArgs>({
        page: 0,
        limit: 20,
        offset: 0,
        count: 0
    })
    const [status, setStatus] = useState<number>(0);

    useEffect(() => {
        if (schoolInfo.id === null) {
            navigate('/schools');
        } else {
            fetchGroups(Number(schoolInfo.id), filter, pagination.limit, pagination.offset, status);
        }
    }, []);

    useEffect(() => {
        fetchGroups(Number(schoolInfo.id), filter, pagination.limit, pagination.offset, status);
    }, [filter, status, pagination.page]);

    useEffect(() => {
        if (groupsList) {
            setPagination((prev) => {
                return {
                    ...prev,
                    count: groupsList.count
                }
            })
        }
    }, [groupsList]);

    useEffect(() => {
        if (groupsError) {
            snackbar.errorMessage(groupsError ? groupsError : 'Произошла непредвиденная ошибка')
        }
    }, [groupsError]);

    const handlePageChange = (page: number) => {
        setPagination((prev) => {
            return {
                ...prev,
                page,
                offset: 20 * page
            }
        })
    };

    const handleFilterChange = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilter(e.target.value);
    };

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

            <Box>
                <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                    <Typography sx={{mt: 3}} variant={'h5'}><b>{t('school_page.group_page.groups')}</b></Typography>
                </Stack>
                <Typography variant="subtitle1">
                    {t('school_page.group_page.groups_educ_process')} <i><b>{schoolInfo.title}</b></i>
                </Typography>
                {(
                    <Button sx={{mt: 2, pr: 5, pl: 5}} variant={'contained'} disabled={emailCheck} onClick={() => {setDialogOpen(true);}}>
                        {t('school_page.group_page.add_group')}
                    </Button>
                )}
            </Box>
            <GroupsTable
                rows={groupsList ? groupsList.rows : groupsList ? [] : []}
                loading={groupsLoading}
                filter={filter}
                handleFilterChange={handleFilterChange}
                fetchGroups={fetchGroups}
                status={status}
                setStatus={setStatus}
                pagination={pagination}
                setPage={handlePageChange}
            />
            <NewGroupDialog
                open={dialogOpen}
                close={() => {setDialogOpen(false)}}
                updateGroups={() => {fetchGroups(Number(schoolInfo.id), filter, pagination.limit, pagination.offset, status)}}
            />
            </Box>
        </Box>
    );
};

export default GroupsList;
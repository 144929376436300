import React, {useEffect, useState} from 'react';
import {useUserStore} from "../../global/store/UserStore";
import {useNavigate, useParams} from "react-router-dom";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {changeSchoolCategories, getSchoolCategories, getSchoolInfo, updateSchoolByAdmin} from "../actions/api";
import PageHeader from "../../global/components/PageHeader";
import {
    Avatar,
    Box, Button, Checkbox, CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputBase,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {DirectionsCar} from "@mui/icons-material";
import {getLocalitiesByRegionId, getRegions} from "../../Auth/actions/api";
import { globalInputStyle } from '../../global/helpers/globalstyles';
import useLoadingHook from '../../global/hooks/UseLoadingHook';

interface SchoolInfoDto {
    id: number,
    address: string,
    bin: string,
    title: string,
    director: string,
    phone1: string,
    phone2: string | null,
    region: string,
    locality: string | null,
}

const ChangeSchoolAdmin = () => {

    const isAdmin = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();
    const {id} = useParams();
    const [schoolInfo, setSchoolInfo] = useState<SchoolInfoDto>({
        id: 500000,
        address: '',
        bin: '',
        title: '',
        director: '',
        phone1: '',
        phone2: '',
        region: '',
        locality: '',
    });
    const [regionsArray, setRegionsArray] = useState<any[]>([]);
    const [localitiesArray, setLocalitiesArray] = useState<any[]>([]);
    const [changing, setChanging] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [categoriesData, categoriesLoading, , fetchCategories] = useLoadingHook(getSchoolCategories);
    const [categoriesArray, setCategoriesArray] = useState<any[]>([]);

    useEffect(() => {
        if (!isAdmin) {
            navigate('/')
            snackbar.errorMessage('Только администратор имеет право доступа к изменению данных учебной организации.')
        } else {
            getRegions()
                .then((res) => {
                    setRegionsArray(res.data);
                })
            fetchSchoolInfo();
            fetchCategories(Number(id));
        }
    }, []);

    useEffect(() => {
        regionsArray.map((region) => {
            if (region.name_ru === schoolInfo.region) {
                getLocalitiesByRegionId(region.id)
                    .then((res) => {
                        console.log(res);
                        if (region.id === 1 || region.id === 2 || region.id === 3) {
                            setLocalitiesArray(res.data);
                        } else {
                            setLocalitiesArray([
                                ...res.data,
                                {
                                    id: 50000,
                                    name_ru: 'Другое (вписать в адрес)'
                                }
                            ])
                        }

                        if (schoolInfo.locality && res.data.includes(schoolInfo.locality)) {
                            setLocality(schoolInfo.locality)
                        }
                    })
            }
        });
    }, [schoolInfo.region]);

    useEffect(() => {
        if (categoriesData) {
            const array = categoriesData?.map((category: any) => {
                return category.category_id
            });
    
            setCategoriesArray(array);
        }
    }, [categoriesData]);

    const fetchSchoolInfo = () => {
        getSchoolInfo(Number(id))
            .then((res) => {
                console.log(res.data);
                setSchoolInfo(res.data);
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
    }

    const handleRegionChange = (e: SelectChangeEvent<string>) => {
        const chosenRegion = e.target.value;
        setRegion(chosenRegion);
    };

    const setRegion = (chosenRegion: string) => {
        setSchoolInfo((prev) => {
            return {
                ...prev,
                region: chosenRegion
            }
        })
    };

    const handleLocalityChange = (e: SelectChangeEvent<string>) => {
        const chosenLocality = e.target.value;
        setLocality(chosenLocality);
    };

    const setLocality = (chosenLocality: string) => {
        if (chosenLocality === 'Другое (вписать в адрес)') {
            setSchoolInfo((prev) => {
                return {
                    ...prev,
                    locality: 'Другое (вписать в адрес)'
                }
            })
            return;
        }

        setSchoolInfo((prev) => {
            return {
                ...prev,
                locality: chosenLocality
            }
        })
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSchoolInfo((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    };

    const handleCategoriesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        const name = e.target.name;

        if (checked) {
            const newCategories = categoriesArray.concat(name);

            setCategoriesArray(newCategories)
        }

        if (!checked) {
            const isIncluded = categoriesArray.includes(name);

            if (isIncluded) {
                const newCategories : string[] = [];

                for (let i = 0; i < categoriesArray.length; i++) {
                    const element = categoriesArray[i];

                    if (element !== name) {
                        newCategories.push(element);
                    }
                }

                setCategoriesArray(newCategories)
            }
        }
    }

    const startChange = () => {
        setChanging(true);
    };

    const cancelChange = () => {
        fetchSchoolInfo();
        fetchCategories(Number(id));
        setChanging(false);
    };

    const checkSchoolInfo = () => {
        if (!schoolInfo.bin || !schoolInfo.title || !schoolInfo.director || !schoolInfo.region || !schoolInfo.address || !schoolInfo.phone1) {
            return false;
        } else {
            return true;
        }
    };

    const handleSubmit = () => {
        let schoolUpdateDto = {
            ...schoolInfo,
            locality: schoolInfo.locality === 'Другое (вписать в адрес)' ? '' : schoolInfo.locality
        };

        const isFormValid = checkSchoolInfo();

        if (!isFormValid) {
            snackbar.errorMessage(`Необходимо заполнить все требуемые поля (Название, директор, регион, адрес, номер телефона (основной))`);
            return;
        }

        setChanging(false);

        updateSchoolByAdmin(schoolUpdateDto)
            .then((res) => {
                console.log(res);
                changeSchoolCategories({school_id: Number(id), categories: categoriesArray})
                    .then((res) => {
                        console.log(res);
                    })
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
                fetchSchoolInfo();
                fetchCategories(Number(id));
            })
            .finally(() => {
                setLoading(false);
            })
    };

    return (
        <Box sx={{pt: 1, pr: 5, pl: 5, pb: 5}}>

            <PageHeader />

            <Stack flexDirection="row" alignItems="center" sx={{mt: 1, mb: 3}}>
                <Avatar
                    sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2, width: 50, height: 50}}
                >
                    <DirectionsCar fontSize="medium"/>
                </Avatar>
                <Stack>
                    <Typography variant="h5" color="dimgray">Учебная организация по подготовке водителей транспортных средств</Typography>
                    <Typography variant="h4" sx={{mt: 0.3}}>{schoolInfo?.title}</Typography>
                </Stack>
            </Stack>

            <Box flexDirection={'row'}>
                <Stack gap={1}>
                    <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '5px'}} ><b>Изменить данные школы:</b></Typography>

                    <FormControl>
                        <InputBase
                            value={schoolInfo.bin}
                            disabled={changing ? false : true}
                            name={'bin'}
                            onChange={handleInputChange}
                        />
                        <FormHelperText id={'region-label'}>БИН</FormHelperText>
                    </FormControl>

                    <FormControl>
                        <InputBase
                            value={schoolInfo.title}
                            name={'title'}
                            disabled={changing ? false : true}
                            onChange={handleInputChange}
                        />
                        <FormHelperText id={'region-label'}>Название учебной организации</FormHelperText>
                    </FormControl>

                    <FormControl>
                        <InputBase
                            value={schoolInfo.director}
                            disabled={changing ? false : true}
                            name={'director'}
                            onChange={handleInputChange}
                        />
                        <FormHelperText id={'region-label'}>Директор</FormHelperText>
                    </FormControl>

                    <FormControl>
                        <Select
                            labelId={'region-label'}
                            onChange={handleRegionChange}
                            name={'region'}
                            value={schoolInfo.region}
                            required={true}
                            disabled={changing ? false : true}
                            sx={{
                                borderRadius: '16px',
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderWidth: 0,
                                    borderRadius: '16px',
                                }
                            }}
                        >
                            {regionsArray.map((region: any) => {
                                return (
                                    <MenuItem
                                        value={region.name_ru}
                                        key={region.name_ru}
                                    >
                                        {region.name_ru}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                        <FormHelperText id={'region-label'}>Регион</FormHelperText>
                    </FormControl>

                    <FormControl>
                        <Select
                            labelId={'locality-label'}
                            onChange={handleLocalityChange}
                            name={'locality'}
                            value={schoolInfo.locality ? schoolInfo.locality : ''}
                            required={true}
                            disabled={localitiesArray.length === 0 || !changing}
                            sx={{
                                borderRadius: '16px',
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderWidth: 0,
                                    borderRadius: '16px',
                                }
                            }}
                        >
                            {localitiesArray.map((locality: any) => {
                                return (
                                    <MenuItem
                                        value={locality.name_ru}
                                        key={locality.name_ru}
                                    >
                                        {locality.name_ru}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                        <FormHelperText id={'locality-label'}>Населенный пункт</FormHelperText>
                    </FormControl>

                    <FormControl>
                        <InputBase
                            value={schoolInfo.address}
                            name={'address'}
                            disabled={changing ? false : true}
                            onChange={handleInputChange}
                        />
                        <FormHelperText id={'region-label'}>Адрес</FormHelperText>
                    </FormControl>

                    <FormControl>
                        <InputBase
                            value={schoolInfo.phone1}
                            name={'phone1'}
                            disabled={changing ? false : true}
                            onChange={handleInputChange}
                        />
                        <FormHelperText id={'region-label'}>Номер телефона (основной)</FormHelperText>
                    </FormControl>

                    <FormControl>
                        <InputBase
                            value={schoolInfo.phone2}
                            name={'phone2'}
                            disabled={changing ? false : true}
                            onChange={handleInputChange}
                        />
                        <FormHelperText id={'region-label'}>Номер телефона (дополнительный)</FormHelperText>
                    </FormControl>

                    <Stack flexDirection={'column'}>
                        <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '5px'}} ><b>Категории учебной организации, по которым разрешено проводить обучение:</b></Typography>
                        {categoriesLoading && <Stack flexDirection={'row'} justifyContent={'center'}><CircularProgress /></Stack>}
                        {!categoriesLoading && (<>
                            <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('A')} disabled={changing ? false : true} name={'A'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'А'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('B')} disabled={changing ? false : true} name={'B'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'B'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('BE')} disabled={changing ? false : true} name={'BE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'BE'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('C')} disabled={changing ? false : true} name={'C'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('CE')} disabled={changing ? false : true} name={'CE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'CE'} />
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('D')} disabled={changing ? false : true} name={'D'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('DE')} disabled={changing ? false : true} name={'DE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'DE'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('Tb')} disabled={changing ? false : true} name={'Tb'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'Tb'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('Tm')} disabled={changing ? false : true} name={'Tm'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'Tm'} />
                            </Box>
                        </>)}
                    </Stack>

                    <Stack flexDirection={'column'}>
                        <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '5px'}} ><b>Подкатегории учебной организации, по которым разрешено проводить обучение:</b></Typography>

                        {categoriesLoading && <Stack flexDirection={'row'} justifyContent={'center'}><CircularProgress /></Stack>}
                        {!categoriesLoading && (<>
                            <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('A1')} disabled={changing ? false : true} name={'A1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'A1'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('B1')} disabled={changing ? false : true} name={'B1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'B1'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('C1')} disabled={changing ? false : true} name={'C1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C1'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('C1E')} disabled={changing ? false : true} name={'C1E'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C1E'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('D1')} disabled={changing ? false : true} name={'D1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D1'} />
                            </Box>

                            <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('BC1')} disabled={changing ? false : true} name={'BC1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'BC1'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={categoriesArray.includes('D1E')} disabled={changing ? false : true} name={'D1E'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D1E'} />
                            </Box>
                        </>)}
                    </Stack>


                    {!loading && (<Stack flexDirection={'row'} justifyContent={'flex-end'} gap={1}>
                        {!changing && <Button
                            onClick={startChange}
                            variant={'contained'}
                        >
                            Начать редактирование
                        </Button>}
                        {changing && <Button
                            onClick={cancelChange}
                            variant={'contained'}
                            color={'error'}
                        >
                            Отменить редактирование
                        </Button>}
                        <Button
                            onClick={handleSubmit}
                            variant={'contained'}
                        >
                            Сохранить
                        </Button>
                    </Stack>)}

                    {loading && (<Stack flexDirection={'row'} justifyContent={'flex-end'} gap={1}>
                        <CircularProgress />
                    </Stack>)}
                </Stack>
            </Box>

        </Box>
    );
};

export default ChangeSchoolAdmin;
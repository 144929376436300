import React, { FormEvent, useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle, FormControl, InputBase, InputLabel, MenuItem,
    Select, SelectChangeEvent, Stack,
    TextField,
    Typography
} from "@mui/material";
import PageHeader from "../../global/components/PageHeader";
import {useParams} from "react-router-dom";
import {useSchoolStore} from "../../School/store/SchoolStore";
import TransportTable from "../components/TransportTable";
import {addTransportToSchool, getSchoolTransport} from "../actions/api";
import {TransportCreateDto} from "../actions/transport.type";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import { useUserStore } from '../../global/store/UserStore';
import {wrapperSx} from "../../global/helpers/globalstyles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {useTranslation} from "react-i18next";

const TransportPage = () => {

    const {id} = useParams();

    const {t} = useTranslation();

    const snackbar = useSnackbarStore((state) => state);
    const schoolInfo = useSchoolStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [transportInfo, setTransportInfo] = useState<any[]>([]);
    const [transportForm, setTransportForm] = useState<TransportCreateDto>({
        plate_number: '',
        description: '',
        school_id: schoolInfo.id ? schoolInfo.id : null
    });
    const [addLoading, setAddLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchTransport();
    }, []);

    const transportText = {
        title: t('school_page.transport_page.title'),
        subtitle: t('school_page.transport_page.subtitle'),
        button: t('school_page.transport_page.button'),
        newTransport: t('school_page.transport_page.new_transport')
    }

    const fetchTransport = () => {
        setLoading(true);
        getSchoolTransport(Number(id))
            .then((res) => {
                console.log(res);
                setTransportInfo(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const cleanForm = () => {
        setTransportForm({
            plate_number: '',
            description: '',
            school_id: schoolInfo.id ? schoolInfo.id : null
        })
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTransportForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    };

    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        setTransportForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setAddLoading(true);
        addTransportToSchool(transportForm)
            .then((res) => {
                snackbar.successMessage(t('school_page.transport_page.messages.car_added'));
                cleanForm();
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setAddLoading(false);
                fetchTransport();
            })
    }

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <Box>
                    <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                        <Typography sx={{mt: 3}} variant={'h5'}><b>{transportText.title}</b></Typography>
                        <Typography variant="subtitle2">
                            {transportText.subtitle} <i><b>{schoolInfo.title}</b></i>
                        </Typography>
                    </Stack>
                    {!isAdmin && !dialogOpen && (
                        <Button
                            variant={'contained'}
                            onClick={() => {setDialogOpen(true)}}
                            sx={{mt: '27px'}}
                        >
                            {transportText.button} <KeyboardArrowDownIcon style={{marginLeft: '3rem'}}/>
                        </Button>
                    )}

                    {dialogOpen && <Box sx={{width: '773px', height: 'auto', p: '32px', background: '#fff', borderRadius: '16px', mt: '17px'}}>
                        <form style={{display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px'}} onSubmit={handleSubmit}>
                            <p
                                onClick={() => {setDialogOpen(false)}}
                            >
                                <Typography
                                    variant={'h5'}
                                    sx={{
                                        mb: '14px',
                                        alignItems: 'center',
                                        display: 'flex'
                                    }}
                                >
                                    {transportText.newTransport} <KeyboardArrowUpIcon style={{marginLeft: 'auto'}}/>
                                </Typography>
                            </p>

                            <TextField
                                value={transportForm.plate_number}
                                onChange={handleInputChange}
                                name={'plate_number'}
                                label={t('school_page.transport_page.labels.plate_number')}
                                required={true}
                                helperText={t('school_page.transport_page.helpers.plate_number')}
                            />
                            <FormControl>
                                <InputLabel id={'description-label'}>{t('school_page.transport_page.labels.transmission')}</InputLabel>
                                <Select
                                    labelId={'description-label'}
                                    onChange={handleSelectChange}
                                    name={'description'}
                                    label={t('school_page.transport_page.labels.transmission')}
                                    value={transportForm.description ? transportForm.description : ''}
                                >
                                    <MenuItem
                                        value={'АКПП'}
                                    >
                                        АКПП
                                    </MenuItem>
                                    <MenuItem
                                        value={'МКПП'}
                                    >
                                        МКПП
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            {!addLoading && (
                                <Button
                                    variant={'contained'}
                                    type={'submit'}
                                    sx={{width: '227px', mt: '14px'}}
                                >
                                    {t('school_page.transport_page.add')}
                                </Button>
                            )}
                            {addLoading && (<CircularProgress />)}
                    </form>
                    </Box>}
                </Box>

                <TransportTable
                    rows={transportInfo}
                    loading={loading}
                    fetchTransport={fetchTransport}
                />
            </Box>
        </Box>
    );
};

export default TransportPage;
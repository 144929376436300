import {
    Box,
    Button, Checkbox,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl, FormControlLabel,
    InputBase,
    InputLabel, MenuItem,
    Paper, Select,
    Stack,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import {DeleteOutline, Download} from '@mui/icons-material';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";

interface ThirdStepProps {
    userForm: any,
    handleUserChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    registerLoading: boolean,
    nextStep: (step: number) => void
}

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };

export const ThirdStep = ({userForm, handleUserChange, registerLoading, nextStep} : ThirdStepProps) => {

    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();

    const { t } = useTranslation();

    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [hidden, setHidden] = useState<boolean>(true);
    const [offerAccepted, setOfferAccepted] = useState<boolean>(false);
    const [offerOpen, setOfferOpen] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setConfirmPassword(e.target.value);
    };
    const handleFinish = () => {
        nextStep(3);
    }
    const handleOpenOffer = () => {

        if (!userForm.iin || !userForm.first_name || !userForm.last_name || !userForm.email || !userForm.phone) {
            snackbar.errorMessage(t('auth.third-step.required-data'));
            return;
        }

        if (!userForm.password || !confirmPassword) {
            snackbar.errorMessage(t('auth.third-step.required-passwords'));
            return;
        }

        if (/^[a-zA-Z0-9$#!@%]*$/.test(userForm.password)) {
            setOfferOpen(true);
        } else if (confirmPassword !== userForm.password) {
            snackbar.errorMessage(t('auth.third-step.mismatch-passwords'))
            return;
        } else {
            snackbar.errorMessage(t('auth.third-step.invalid-password'));
            return;
        }
    }

    return (
        <Stack flexDirection={'column'}>
            <Stack flexDirection={'row'} sx={{p: '36px', background: '#FFF'}} gap={'36px'}>
                <Stack flexDirection={'column'} sx={{width: '50%'}} gap={'16px'}>
                    <Typography sx={{background: '#F8F9FB', p: '8px 16px'}} ><b>{t('auth.third-step.user-info')}</b></Typography>

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.third-step.iin')}
                        value={userForm.iin}
                        name={'iin'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.third-step.last_name')}
                        value={userForm.last_name}
                        name={'last_name'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.third-step.first_name')}
                        value={userForm.first_name}
                        name={'first_name'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.third-step.patronymic')}
                        value={userForm.patronymic}
                        name={'patronymic'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={false}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.third-step.email')}
                        value={userForm.email}
                        name={'email'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.third-step.phone')}
                        value={userForm.phone}
                        name={'phone'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />
                </Stack>

                <Stack flexDirection={'column'} sx={{width: '50%'}} gap={'16px'}>
                    <Typography sx={{background: '#F8F9FB', p: '8px 16px'}} ><b>{t('auth.third-step.password-info')}</b></Typography>

                    <InputBase
                        type={hidden ? 'password' : 'text'}
                        placeholder={t('auth.third-step.password')}
                        value={userForm.password}
                        name={'password'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />
                    <InputBase
                        type={hidden ? 'password' : 'text'}
                        placeholder={t('auth.third-step.repeat-password')}
                        value={confirmPassword}
                        name={'password'}
                        onChange={handleChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    {hidden && <Button
                        variant={'outlined'}
                        color={'info'}
                        onClick={() => {setHidden(false)}}
                    >
                        {t('auth.third-step.show')}
                    </Button>}
                    {!hidden && <Button
                        variant={'outlined'}
                        color={'info'}
                        onClick={() => {setHidden(true)}}
                    >
                        {t('auth.third-step.hide')}
                    </Button>}
                </Stack>

            </Stack>
            <Stack flexDirection={'row'} justifyContent={'center'} gap={'20px'} sx={{mt: '36px'}}>
                <Button
                    onClick={() => {nextStep(1)}}
                    color={'info'}
                    variant={'outlined'}
                    sx={{width: '207px', height: '56px'}}
                >
                    {t('auth.third-step.back')}
                </Button>
                <Button
                    onClick={() => {handleOpenOffer()}}
                    variant={'contained'}
                    sx={{width: '207px', height: '56px'}}
                >
                    {t('auth.third-step.next')}
                </Button>

            </Stack>

            <Dialog
                open={offerOpen}
                onClose={() => {setOfferOpen(false)}}
                maxWidth={'xl'}
            >
                <DialogTitle>
                    <b>{t('auth.third-step.offer-title')}</b>
                </DialogTitle>
                <DialogContent sx={{fontFamily: 'sans-serif'}}>
                    <a href="/UserOffer.pdf" download="Договор оферты" style={{display: 'block', width: 'fit-content', textDecoration: 'none', color: 'black'}}>
                        <Stack
                          display={'flex'}
                          flexDirection={'column'}
                          sx={{
                              width: '300px',
                              height: 'auto',
                              minHeight: '150px',
                              borderRadius: '16px',
                              backgroundColor: '#fafafa',
                              py: '20px',
                              px: '16px',
                              gap: '16px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                          }}
                        >
                            <Typography sx={{textAlign: 'center'}}>{t('auth.third-step.downlaod-offer')}</Typography>
                            <Download fontSize={'large'}/>
                        </Stack>
                    </a>
                </DialogContent>
                <DialogActions sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 2}}>
                    <Button
                        onClick={() => {
                            setOfferAccepted(false);
                            setOfferOpen(false);
                        }}
                        variant={'contained'}
                        color={'error'}
                        disabled={registerLoading}
                    >
                        {t('auth.third-step.refuse')}
                    </Button>

                    {!registerLoading && (<Button
                        onClick={() => {
                            setOfferAccepted(true);
                            handleFinish();
                        }}
                        variant={'contained'}
                    >
                        {t('auth.third-step.accept')}
                    </Button>)}

                    {registerLoading && <CircularProgress />}
                </DialogActions>
            </Dialog>
        </Stack>
    )
};

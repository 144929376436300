import { DirectionsCar, ExpandMoreOutlined } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    CircularProgress,
    Divider,
    Stack,
    Typography
} from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import { GroupStatusChip } from '../../Group/components/GroupsList/GroupStatusChip'
import { formatDate } from '../../global/helpers/text'
import useLoadingHook from '../../global/hooks/UseLoadingHook'
import {apiGetAllGroups, apiGetGroupsStatistics} from '../actions/api'
import NoRows from '../../global/components/NoRows'
import SchoolIcon from '@mui/icons-material/School'
import { Link } from 'react-router-dom'

const GroupsStatistic = () => {

    const [groupsList, groupsLoading, , fetchGroups] = useLoadingHook(apiGetAllGroups);
    const [statisticsResponse, statisticsLoading, statisticsError, fetchStatistics] = useLoadingHook(apiGetGroupsStatistics);

    useEffect(() => {
        fetchGroups();
        fetchStatistics();
    }, [])

    const columns: GridColDef[] = [
        {
            field: 'title', 
            headerName: 'Название',
            minWidth: 200, 
            sortable: false,
            renderCell: ({row}) => {
                return <React.Fragment>
                    <Link 
                        to={`/groups/${row.id}`} 
                        style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        {row.title}
                    </Link>
                </React.Fragment>;
            }
        },
        {field: 'category_id', headerName: 'Категория', width: 120, sortable: false},
        {
            field: 'status', headerName: 'Статус', width: 200, sortable: false,
            renderCell: ({value}) => <GroupStatusChip status={value}/>
        },
        {
            field: 'start_date', headerName: 'Начало', width: 140, sortable: false,
            valueFormatter: ({value}) => formatDate(value)
        },
        {
            field: 'end_date', headerName: 'Конец', width: 140, sortable: true,
            valueFormatter: ({value}) => formatDate(value)
        },
        {
            field: 'school.title',
            headerName: 'Название учебной организации',
            headerClassName: 'datagrid--header',
            minWidth: 500,
            sortable: false,
            flex: 1,
            renderCell: ({row}) => {
                return <React.Fragment>
                    <Link 
                        to={`/schools/${row.school.id}`} 
                        style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        <Avatar
                            sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2}}
                        >
                            <DirectionsCar fontSize="small"/>
                        </Avatar>
                        {row.school.title}
                    </Link>
                </React.Fragment>;
            }
        },
    ];

    return (
        
        <Accordion sx={{mt: 2}}>
            <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
            >
                    <Stack flexDirection="row" alignItems="center" sx={{mt: 1, mb: 1}}>
                        <Avatar
                            sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2, width: 50, height: 50}}
                        >
                            <SchoolIcon fontSize="medium"/>
                        </Avatar>
                        <Stack>
                            <Typography variant="h5">Информация по группам</Typography>
                        </Stack>
                    </Stack>
            </AccordionSummary>
            <AccordionDetails>

                <Typography variant={'subtitle2'} sx={{mb: 2}}>В данном блоке отражены все учебные группы, зарегистрированные в системе. Изначальная сортировка построена от самой ранней даты завершения обучения к самой поздней.</Typography>

                <Stack
                    flexDirection={'column'}
                >
                    {statisticsLoading && <CircularProgress />}
                    {statisticsResponse && groupsList && (
                      <Stack>
                          <Box flexDirection={'row'}>
                              <Typography variant={'h6'}>Всего зарегистрировано учебных групп: <b>{groupsList.count}</b></Typography>
                          </Box>
                          {statisticsResponse.regions.map((regionStatistic: any) => {
                              return (
                                <Stack key={`${regionStatistic.region}-groups`}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreOutlined />}
                                        >
                                            <Typography>{regionStatistic.region}: {regionStatistic.total}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography fontWeight={'bold'} fontSize={'14px'}>
                                                Разбивка по категориям:
                                            </Typography>
                                            {regionStatistic.statistics.map((stats: any) => {
                                                return (
                                                  <Stack key={`${stats.category}-${regionStatistic.region}-groups`}>
                                                      <Typography fontSize={'14px'}>
                                                          Категория "{stats.category}": {stats.count}
                                                      </Typography>
                                                  </Stack>
                                                )
                                            })}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Divider />
                                </Stack>
                              )
                          })}
                      </Stack>
                    )}
                </Stack>

                <Divider
                    sx={{
                        my: 2
                    }}
                />

                <DataGrid
                    columns={columns}
                    rows={groupsList ? groupsList.rows : []}
                    loading={groupsLoading}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                            },
                        },
                    }}
                    autoHeight={true}
                    pageSizeOptions={[20]}
                    disableRowSelectionOnClick={true}
                    style={{margin: '0'}}
                    slots={{
                        noRowsOverlay: NoRows
                    }}
                />
            </AccordionDetails>
        </Accordion>
    )
}

export default GroupsStatistic
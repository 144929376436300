import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    InputBase, Pagination,
    Stack,
    Typography,
    useTheme
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import { getUserSchools } from '../actions/api';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import {
    DataGrid,
    GridColDef,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';
import {DirectionsCar, Person} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from '../../global/store/UserStore';
import { getName } from '../../global/helpers/text';
import {useTranslation} from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import {SchoolInfoType} from "../../global/actions/types.api";
import {PaginationItem} from "@mui/lab";
import CustomDataGrid from "../../global/components/CustomDataGrid";

interface SchoolsListComponentProps {
    withoutSearch?: boolean
}

const SchoolsListComponent = ({withoutSearch}: SchoolsListComponentProps) => {

    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);

    const { t } = useTranslation();

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

    const [rows, setRows] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('');
    const [filteredRows, setFilteredRows] = useState<any[]>([]);

    useEffect(() => {
        if (!filter) {
            setFilteredRows(rows);
        } else if (isAdmin && filter) {
            const lowerCaseFilter = filter.toLowerCase();
            const filteredRows = JSON.parse(JSON.stringify(rows)).filter((row: any) => {
                if (row.bin.includes(lowerCaseFilter)) {
                    return true;
                } else if (row.title.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                } else if (`${row.user.last_name || ""} ${row.user.first_name}`?.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                } else if (row.user.iin?.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                } else {
                    return false;
                }
            });

            setFilteredRows(filteredRows);
        } else if (!isAdmin && filter) {
            const lowerCaseFilter = filter.toLowerCase();
            const filteredRows = JSON.parse(JSON.stringify(rows)).filter((row: any) => {
                if (row.bin.includes(lowerCaseFilter)) {
                    return true;
                } else if (row.title.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                } else {
                    return false;
                }
            });

            setFilteredRows(filteredRows);
        }
    }, [filter])

    useEffect(() => {
        setLoading(true);
        getUserSchools()
            .then((res) => {
                setRows(res.data);
            })
            .catch((e) => {
                snackbar.errorMessage(e.response.data.message);
                if (e.response.data.statusCode === 401) {
                    navigate('/auth');
                }
            })
            .finally(() => {
                setLoading(false);
            })
        // eslint-disable-next-line
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'empty',
            headerName: '',
            headerClassName: 'datagrid--header',
            width: 46,
            sortable: false,
        },
        {
            field: 'id',
            headerName: 'ID',
            headerClassName: 'datagrid--header',
            width: 90,
            sortable: true,
        },
        {
            field: 'bin',
            headerName: t('register.school_list_component.grid.bin'),
            headerClassName: 'datagrid--header',
            width: 200,
            sortable: false,
        },
        {
            field: 'title',
            headerName: t('register.school_list_component.grid.title'),
            headerClassName: 'datagrid--header',
            width: 500,
            sortable: false,
            renderCell: (params) => {
                return <React.Fragment>
                    <Link
                      to={`/schools/${params.row.id}`}
                      style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
                      // target="_blank"
                      // rel="noopener noreferrer"
                    >
                        <Avatar
                          sx={{color: '#1E9AF3', bgcolor: '#fff', mr: 2}}
                        >
                            <DirectionsCar fontSize="small"/>
                        </Avatar>
                        {params.value}
                    </Link>
                </React.Fragment>;
            }
        }
    ];

    const superAdminColumns: GridColDef[] = [
        {
            field: 'empty',
            headerName: '',
            headerClassName: 'datagrid--header',
            maxWidth: 10,
            width: 10,
            sortable: false,
        },
        {
            field: 'id',
            headerName: 'ID',
            headerClassName: 'datagrid--header',
            width: 90,
            sortable: true,
        },
        {
            field: 'bin',
            headerName: t('register.school_list_component.grid.bin'),
            headerClassName: 'datagrid--header',
            width: 200,
            sortable: false,
        },
        {
            field: 'title',
            headerName: t('register.school_list_component.grid.title'),
            headerClassName: 'datagrid--header',
            minWidth: 400,
            width: 500,
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                  <Link
                      to={`/schools/${params.row.id}`}
                      style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                        <Avatar
                          sx={{color: '#1E9AF3', bgcolor: '#fff', mr: 2}}
                        >
                            <DirectionsCar fontSize="small"/>
                        </Avatar>
                        {params.value}
                    </Link>
                    )
            }
        },
        {
            field: 'user',
            headerName: t('register.school_list_component.grid.user'),
            headerClassName: 'datagrid--header',
            minWidth: 400,
            renderCell: ({row}) => {
                const name = getName(row.user);
                return <Link to={'/user/' + row.user.id} target={'_blank'} style={{textDecoration: 'none', color: 'black', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#1E9AF3', bgcolor: '#fff'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName} #{row.user.id}
                </Link>;
            }
        }
    ];

    const SchoolCard = ({school}: { school: SchoolInfoType }) => {
        return (
          <Stack
            flexDirection={'column'}
            sx={{
                p: '30px',
                background: '#f5f5f5',
                borderRadius: '20px',
                height: 'auto',
                width: '310px'
            }}
          >
              <Typography
                fontSize={'14px'}
                lineHeight={'140%'}
                letterSpacing={'-0.02'}
                color={'#05304F'}
              >
                  Учебная организация #{school.id}:
              </Typography>
              <Typography
                fontSize={'16px'}
                lineHeight={'140%'}
                letterSpacing={'-0.02'}
                color={'#05304F'}
              >
                  {school.title}
              </Typography>
              <Typography
                fontSize={'14px'}
                lineHeight={'140%'}
                letterSpacing={'-0.02'}
                color={'#05304F'}
                sx={{
                    mt: 1
                }}
              >
                  БИН:
              </Typography>
              <Typography
                fontSize={'16px'}
                lineHeight={'140%'}
                letterSpacing={'-0.02'}
                color={'#05304F'}
              >
                  {school.bin}
              </Typography>
              <Button
                variant={'contained'}
                sx={{
                    mt: 5,
                    height: '40px'
                }}
                onClick={() => navigate(`/schools/${school.id}`)}
              >
                  Перейти
              </Button>
          </Stack>
        )
    }

    if (isMobile && rows) {
        return (
          <Box>
              <Stack
                flexDirection={'column'}
                gap={'16px'}
              >
                  {!withoutSearch && (
                    <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <Typography sx={{mt: 3}} variant={'h5'}><b>{t('school_page.employees_page.schools_list_page')}</b></Typography>
                    </Stack>
                  )}

                  {!withoutSearch && <InputBase
                    type={'text'}
                    onChange={(e) => {
                        setFilter(e.target.value);
                    }}
                    value={filter}
                    placeholder={'Поиск'}
                    sx={{mb: 1}}
                  />}

                  {loading && <CircularProgress />}

                  {!filter && !loading && rows.length > 0 && rows?.map((row) => {
                      return (
                        <SchoolCard
                            school={row}
                        />
                      )
                  })}

                  {filter && filteredRows.map((row) => {
                      return (
                        <SchoolCard
                          school={row}
                        />
                      )
                  })}
              </Stack>
          </Box>
        )
    }

    return (
        <Box>
            <Stack
                flexDirection="row"
                flexWrap="wrap"
                sx={{mb: 2}}
            >
                {!withoutSearch && (
                    <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <Typography sx={{mt: 3}} variant={'h5'}><b>{t('school_page.employees_page.schools_list_page')}</b></Typography>
                    </Stack>
                )}
            </Stack>
            {!withoutSearch && <InputBase
                type={'text'}
                onChange={(e) => {
                    setFilter(e.target.value);
                }}
                placeholder={'Поиск'}
                sx={{mb: 1}}
                value={filter}
            />}
            <CustomDataGrid
                columns={isAdmin ? superAdminColumns : columns}
                rows={filter ? filteredRows : rows}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20,
                        },
                    },
                }}
                autoHeight={true}
                pageSizeOptions={[20]}
                disableRowSelectionOnClick
                loading={loading}
                disableColumnMenu={true}
                disableColumnFilter={true}
            />
        </Box>
    );
};

export default SchoolsListComponent;

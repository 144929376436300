import React, {ChangeEvent, useEffect, useState} from 'react';
import {
    Box, Button, Dialog, DialogContent, DialogTitle, Stack, Tab, Tabs, Typography
} from '@mui/material';

import '../styles/RegisterPage.scss';
import {
    IRegisterAttributes,
    IRegisterSchool,
    IRegisterUser,
    RegisterEmployeeDto,
    RegisterTransportDto
} from "../dto/AuthDto";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {apiRegisterAsStudent, getLocalitiesByRegionId, getRegions, register, registerByAdmin} from '../actions/api';
import PageHeader from "../../global/components/PageHeader";
import { FirstStep } from '../components/FirstStep';
import { ThirdStep } from '../components/ThirdStep';
import { useUserStore } from '../../global/store/UserStore';
import { Link, useNavigate } from 'react-router-dom';
import FourthStep from '../components/FourthStep';
import {Looks3, Looks4, LooksOne, LooksTwo} from '@mui/icons-material';
import SecondStep from "../components/SecondStep";
import {RegisterStudentComponent} from "../components/RegisterStudentComponent";
import useLoadingHook from "../../global/hooks/UseLoadingHook";

const RegisterAsStudentPage = () => {

    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);
    const [userForm, setUserForm] = useState<IRegisterUser>({
        iin: '',
        gender: 0,
        first_name: '',
        last_name: '',
        patronymic: '',
        email: '',
        phone: '',
        address: '',
        password: ''
    });

    const [registerResponse, registerLoading, registerError, requestRegister] = useLoadingHook(apiRegisterAsStudent);

    useEffect(() => {
        if (registerResponse) {
            cleanInputs();
            snackbar.successMessage('Вы успешно зарегистрировались! Перенаправляем на страницу авторизации.');
            navigate('/auth');
        }

        if (registerError) {
            snackbar.errorMessage(registerError?.response?.data?.message ? registerError?.response?.data?.message : 'Произошла непредвиденная ошибка')
        }
    }, [registerResponse, registerError]);

    const handleUserChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setUserForm((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const finishReg = () => {
        requestRegister(userForm)
            .then(() => {
                snackbar.successMessage('Вы успешно зарегистрировались! Перенаправляем на страницу авторизации.')
            })
    };

    const cleanInputs = () => {
        setUserForm({
            iin: '',
            gender: 0,
            first_name: '',
            last_name: '',
            patronymic: '',
            email: '',
            phone: '',
            address: '',
            password: ''
        });
    }

    const checkUserForm = () => {
        return !(!userForm.iin || !userForm.email || !userForm.phone || !userForm.last_name || !userForm.first_name);
    }

    return (
        <Box className={'register App'} id={'register'} sx={{p: 5}}>
            <PageHeader />
            <Stack flexDirection={'row'} justifyContent={'center'} sx={{mt: '21px'}}>
                <Typography variant={'h6'}>Регистрация в качестве студента</Typography>
            </Stack>

            <Stack>
                <Link to={'/landing'} style={{width: 'fit-content', color: 'grey'}}>Вернуться на главную страницу</Link>
            </Stack>

            <RegisterStudentComponent
                userForm={userForm}
                handleUserChange={handleUserChange}
                registerLoading={registerLoading}
                finishReg={finishReg}
            />
        </Box>
    );
};

export default RegisterAsStudentPage;
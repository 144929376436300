import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Paper,
    Select, SelectChangeEvent,
    Stack,
    SxProps,
    Theme, Tooltip,
    Typography
} from "@mui/material";
import {GroupDto} from "../../actions/group.type";
import {useSchoolStore} from "../../../School/store/SchoolStore";
import {blue} from "@mui/material/colors";
import { attachTransportToGroup, detachTransportFromGroup } from "../../actions/api";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import ClearIcon from "@mui/icons-material/Clear";
import {getSchoolTransport} from "../../../Transport/actions/api";
import {DirectionsCar} from "@mui/icons-material";
import { useUserStore } from '../../../global/store/UserStore';
import {useTranslation} from "react-i18next";

const paperSx: SxProps<Theme> = {
    boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 6px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
    padding: 3,
    borderRadius: 4
};

interface TransportWindowProps {
    groupInfo: GroupDto;
    fetchGroupInfo: () => void;
}

interface TransportModalProps {
    groupInfo: GroupDto;
    fetchGroupInfo: () => void;
    open: boolean;
    onClose: () => void;
}

const TransportModal = ({groupInfo, fetchGroupInfo, open, onClose} : TransportModalProps) => {

    const {t} = useTranslation();

    const [transportList, setTransportList] = useState<any[]>([]);
    const [transportId, setTransportId] = useState<string>('');
    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);

    useEffect(() => {
        getSchoolTransport(Number(schoolInfo.id))
            .then((res) => {
                setTransportList(res.data);
            })
    // eslint-disable-next-line
    }, []);

    const handleSelectChange = (e: SelectChangeEvent) => {
        setTransportId(e.target.value);
    }

    const handleAttach = () => {

        if (!groupInfo.id || !transportId) {
            snackbar.errorMessage(t('school_page.group_page.attributes_window.modal.required.teacher'));
            return;
        }

        const transportDto = {
            group_id: groupInfo.id,
            car_id: Number(transportId)
        }

        attachTransportToGroup(transportDto)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                snackbar.errorMessage(err?.response?.data?.message ? err?.response?.data?.message : 'Произошла непредвиденная ошибка при прикреплении транспорта');
            })
            .finally(() => {
                fetchGroupInfo();
            })
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                {t('school_page.group_page.attributes_window.modal.add_dialog.teacher')} <i><b>{groupInfo.title}</b></i>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Select
                        onChange={handleSelectChange}
                        value={transportId}
                    >
                        {transportList.map((transport) => {
                            return (
                                <MenuItem value={transport.id} key={transport.id}>
                                    <Stack sx={{width: '100%'}} flexDirection="row" alignItems={'center'}>
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            <DirectionsCar />
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack flexDirection="row" alignItems="center" justifyContent="start" gap={1}
                                                   sx={{width: '100%'}}>
                                                <Typography>{transport.model}</Typography>
                                                <Typography variant="body2" color="gray">{transport.plate_number}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            )
                        })}
                    </Select>

                    <Typography variant="body2" sx={{mt: 1}}>
                        {t('school_page.group_page.attributes_window.modal.reminder.teacher')}
                    </Typography>

                    <Button onClick={handleAttach}>
                        {t('school_page.group_page.attributes_window.modal.add_dialog.teacher')}
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

const TransportWindow = ({groupInfo, fetchGroupInfo} : TransportWindowProps) => {

    const {t} = useTranslation();

    const isAdmin = useUserStore((state) => state.isAdmin);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        console.log(`GroupInfo Transport`)
        console.log(groupInfo)
    }, [])

    const handleDetach = () => {
        const transportDto = {
            car_id: groupInfo.transport.car.id,
            group_id: groupInfo.id
        }

        detachTransportFromGroup(transportDto)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                fetchGroupInfo();
            })
    }


    return (
        <Box>
            <Paper sx={{...paperSx, mb: 5}}>
                <Typography variant="h4">{t('school_page.group_page.attributes_window.window.this_group.teacher')}</Typography>
                <Typography variant="subtitle1" sx={{mb: 1.5}} color="gray">
                    {t('school_page.group_page.attributes_window.window.this_group.teacher')}
                </Typography>
                <Stack
                    flexDirection={'column'}
                    gap={'16px'}
                >
                    {groupInfo.transport.length === 0 && (
                        <Typography
                            fontSize={'14px'}
                            color={"gray"}
                        >
                            {t('school_page.group_page.attributes_window.window.no_data')}
                        </Typography>
                    )}
                    {groupInfo.transport.length > 0 && (
                        groupInfo.transport.map((transport: any) => {
                            return (
                                <>
                                    <Stack sx={{ml: 2, width: '400px', p: 2, borderRadius: '10px', background: '#F4F6F8'}} flexDirection="row" alignItems={'center'}>
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            <DirectionsCar />
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack sx={{width: '100%'}}>
                                                <Stack flexDirection="row" alignItems="center" justifyContent="start" gap={1}
                                                       sx={{width: '100%'}}>
                                                    <Typography>{transport.car.model}</Typography>
                                                    <Typography variant="body2" color="gray">{transport.car.plate_number}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        {groupInfo.status === 1 && (<Tooltip title={'Открепить'}>
                                            <ClearIcon
                                                sx={[
                                                    {ml: 5},
                                                    {
                                                        '&:hover': {
                                                            color: 'red',
                                                            cursor: 'pointer'
                                                        },
                                                    },
                                                ]}
                                                onClick={handleDetach}
                                            />
                                        </Tooltip>)}
                                    </Stack>
                                </>
                            )
                        })
                    )}
                    <Button
                        onClick={() => {setIsDialogOpen(true)}}
                        sx={{
                            width: 'fit-content',
                            ml: '16px'
                        }}
                        variant={'contained'}
                    >
                        {t('school_page.group_page.attributes_window.modal.add_btn.teacher')}
                    </Button>
                </Stack>
            </Paper>

            <TransportModal
                open={isDialogOpen}
                groupInfo={groupInfo}
                fetchGroupInfo={fetchGroupInfo}
                onClose={() => {setIsDialogOpen(false)}}
            />
        </Box>
    );
};

export default TransportWindow;
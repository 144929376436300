import React, { useEffect, useState } from 'react'
import { useUserStore } from '../../global/store/UserStore';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import PageHeader from '../../global/components/PageHeader';
import SchoolsStatistic from '../components/SchoolsStatistic';
import GroupsStatistic from '../components/GroupsStatistic';
import StudentsStatistic from "../components/StudentsStatistic";

const StatisticsPage = () => {

    const isAdmin = useUserStore((state) => state.isAdmin);
    const currentUser = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAdmin) {
            snackbar.errorMessage('У вас нет прав доступа к данной странице');
            navigate('/');
        }
    }, []);

  return (
    <Box sx={{pt: 1, pr: 5, pl: 5, pb: 5}}>

      <PageHeader />

      <Typography sx={{mt: 3}} variant={'h5'}><b>Страница статистики</b></Typography>
      <Typography variant={'subtitle2'}>Данная страница доступна только для администраторов системы. Здесь содержится статистика и информация по системе.</Typography>

      <SchoolsStatistic />
      <GroupsStatistic />
      <StudentsStatistic />
    </Box>
  )
}

export default StatisticsPage
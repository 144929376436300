import React, { useEffect, useState } from 'react'
import { MedicalInfoDto } from '../../actions/student.type';
import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { createCorrection } from '../../actions/api';
import { useSnackbarStore } from '../../../global/store/SnackbarStore';
import LoadingButton from '../../../global/components/LoadingButton';

interface CorrectionDialogProps {
    studentId: number;
    groupInfo: any;
    open: boolean;
    onClose: () => void;
}

const CorrectionDialog = ({studentId, groupInfo, open, onClose} : CorrectionDialogProps) => {

    const snackbar = useSnackbarStore((state) => state);
    const [studentForm, setStudentForm] = useState<MedicalInfoDto>({
        student_id: studentId,
        medical_certificate_number: '',
        medical_center_title: '',
        medical_center_bin: '',
        medical_examination_date: ''
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [studentInfo, setStudentInfo] = useState<any>({
        last_name: '',
        first_name: ''
    })

    useEffect(() => {
        const desiredStudent = groupInfo.students.find((student: any) => student.id === studentId);

        setStudentInfo(() => {
            return {
                last_name: desiredStudent.last_name,
                first_name: desiredStudent.first_name,
            }
        });

        setStudentForm((prev) => {
            return {
                ...prev,
                medical_certificate_number: desiredStudent.medical_certificate_number ?? '',
                medical_center_title: desiredStudent.medical_center_title ?? '',
                medical_center_bin: desiredStudent.medical_center_bin ?? '',
                medical_examination_date: desiredStudent.medical_examination_date ?? ''
            }
        });
    }, [studentId]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setStudentForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    };

    const handleSubmit = () => {
        setLoading(true);
        createCorrection(studentForm)
            .then(() => {
                snackbar.successMessage(`Обновление информации успешно произведено. Для отображения информации - обновите страницу`)
                onClose();
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message ? err.response.data.message : 'Произошла непредвиденная ошибка. Обратитесь в службу поддержки')
            })
            .finally(() => {
                setLoading(false);
            })
    };

    return (
        <Dialog
            open={open}
            onClose={() => !loading ? onClose() : snackbar.infoMessage('Дождитесь окончания подачи')}
            maxWidth={'xl'}
        >
            <DialogTitle>
                Заявка на исправление информации курсанта {studentInfo.last_name} {studentInfo.first_name} #{studentForm.student_id}
            </DialogTitle>
            <DialogContent sx={{py: 2}}>

                <Stack flexDirection={'row'} alignItems={'center'} sx={{my: 2}}>
                    <InfoOutlined sx={{color: 'gray', mr: 2}}/>
                    <Stack>
                        <Typography variant={'subtitle2'}><b>Внимание!</b> Посредством данной формы можно заполнить информацию по медицинскому осмотру студента, если это не было сделано при добавлении студента в группу.</Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <Stack flexDirection={'column'} gap={2}>
                        <TextField
                          name={'medical_certificate_number'}
                          value={studentForm.medical_certificate_number}
                          onChange={handleChange}
                          label={'Номер мед. справки'}
                        />
                        <TextField
                          name={'medical_center_title'}
                          value={studentForm.medical_center_title}
                          onChange={handleChange}
                          label={'Название мед. центра'}
                        />
                        <TextField
                          name={'medical_center_bin'}
                          value={studentForm.medical_center_bin}
                          onChange={handleChange}
                          label={'БИН мед. центра'}
                        />
                        <TextField
                          name={'medical_examination_date'}
                          type={'date'}
                          value={studentForm.medical_examination_date}
                          onChange={handleChange}
                          label={'Дата прохождения мед. осмотра'}
                          InputLabelProps={{
                              shrink: true,
                          }}
                        />
                    </Stack>
                </Stack>

                <Stack gap={2} sx={{my: 2}}>
                    <LoadingButton
                        variant={'contained'}
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        Обновить данные
                    </LoadingButton>

                    <LoadingButton
                        variant={'contained'}
                        color={'error'}
                        onClick={onClose}
                        loading={loading}
                    >
                        Отмена
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default CorrectionDialog
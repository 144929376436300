import React, {useEffect, useState} from 'react';
import {DataGrid, GridColDef, GridRenderCellParams, GridRowModel} from "@mui/x-data-grid";
import {Avatar, Box, Button, Chip, CircularProgress, Stack, Typography} from "@mui/material";
import {formatDateTime, getName} from "../../../global/helpers/text";
import {deleteStudentFromGroup, getAllCorrectionsByGroupId} from "../../actions/api";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import { useUserStore } from '../../../global/store/UserStore';
import CorrectionDialog from './CorrectionDialog';
import useLoadingHook from '../../../global/hooks/UseLoadingHook';
import CreateStudentUserModal from "./CreateStudentUserModal";
import {Person} from "@mui/icons-material";
import CopyStudentUserInfoModal from "./CopyStudentUserInfoModal";
import {useTranslation} from "react-i18next";
import {apiGetFeatureEnabled} from "../../../global/actions/main.api";

interface StudentsGridProps {
    rows: any[];
    fetchGroupInfo: () => void;
    // fetchCorrections: (id: any) => void;
    groupInfo: any;
}

const NoRows = () => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', p: 5}}>
            <Typography>
                Нет данных
            </Typography>
        </Box>
    )
}

const StudentsGrid = ({rows, fetchGroupInfo, groupInfo} : StudentsGridProps) => {

    // TODO: добавить обновление corrections после подачи заявки

    const {t} = useTranslation();

    const snackbar = useSnackbarStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const currentUser = useUserStore((state) => state);
    const [loadingIds, setLoadingIds] = useState<number[]>([]);
    const [correctionDialogOpen, setCorrectionDialogOpen] = useState<boolean>(false);
    const [createUserDialogOpen, setCreateUserDialogOpen] = useState<boolean>(false);
    const [copyStudentUserInfoModalOpen, setCopyStudentUserInfoModalOpen] = useState<boolean>(false);
    const [studentId, setStudentId] = useState<number | null>(null);
    const [correctionStudentList, setCorrectionStudentList] = useState<number[]>([]);
    const [attachmentModuleInfoResponse, attachmentModuleInfoLoading, attachmentModuleInfoError, requestAttachmentModuleInfo] = useLoadingHook(apiGetFeatureEnabled);

    const handleCorrectionOpen = (studentId: number) => {
        setStudentId(studentId);
        setCorrectionDialogOpen(true);
    };

    const handleCreateUserOpen = (studentId: number) => {
        setStudentId(studentId);
        setCreateUserDialogOpen(true);
    };

    const handleCopyStudentUserInfoOpen = (studentId: number) => {
        setStudentId(studentId);
        setCopyStudentUserInfoModalOpen(true);
    }

    useEffect(() => {
        requestAttachmentModuleInfo('paid_student_attachments')
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 90,
            sortable: true,
            renderCell: (params: GridRenderCellParams<any>) =>
                params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
        },
        {
            field: 'name', headerName: t('school_page.group_page.students_grid.fio'), minWidth: 350, sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {
            field: 'iin',
            headerName: t('school_page.group_page.students_grid.iin'),
            width: 150,
            sortable: false,
        },
        {
            field: 'user',
            headerName: t('school_page.group_page.students_grid.personal_cabinet'),
            width: 250,
            sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);

                if (row.user_id) {
                    return (
                        <Stack
                            flexDirection={'column'}
                            onClick={() => {handleCopyStudentUserInfoOpen(row.id)}}
                        >
                            <Stack flexDirection={'row'} alignItems={'center'}>
                                <Avatar
                                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                >
                                    <Person />
                                </Avatar>
                                <p>{name.fullName}</p>
                                <p style={{color: 'gray', margin: '0 0 0 5px'}}>#{row.user_id} </p>
                            </Stack>
                        </Stack>
                    );
                } else {
                    return (
                        <Button
                            variant={'contained'}
                            sx={{
                                height: 'auto'
                            }}
                            onClick={() => {handleCreateUserOpen(row.id)}}
                        >
                            {t('school_page.group_page.students_grid.create_personal_cabinet')}
                        </Button>
                    )
                }
            }
        },
        {
            field: 'medical_certificate_number',
            headerName: t('school_page.group_page.students_grid.medical_certificate_number'),
            width: 200,
            sortable: false,
        },
        {
            field: 'medical_center_title',
            headerName: t('school_page.group_page.students_grid.medical_center_title'),
            width: 200,
            sortable: false,
        },
        {
            field: 'medical_center_bin',
            headerName: t('school_page.group_page.students_grid.medical_center_bin'),
            width: 200,
            sortable: false,
        },
        {
            field: 'medical_examination_date',
            headerName: t('school_page.group_page.students_grid.medical_examination_date'),
            width: 300,
            sortable: false,
        },
        {
            field: 'created_at',
            headerName: t('school_page.group_page.students_grid.created_at'),
            width: 180,
            sortable: true,
            valueGetter: ({row}) => formatDateTime(row.created_at)
        },
        {
            field: 'actions',
            headerName: t('school_page.group_page.students_grid.actions'),
            width: 500,
            sortable: false,
            renderCell:({row}: GridRowModel<any>) => GridButtons(row)
        }
    ];

    const columnsWithPaidAttachment: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 90,
            sortable: true,
            renderCell: (params: GridRenderCellParams<any>) =>
              params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
        },
        {
            field: 'name', headerName: t('school_page.group_page.students_grid.fio'), minWidth: 350, sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                      sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {
            field: 'iin',
            headerName: t('school_page.group_page.students_grid.iin'),
            width: 150,
            sortable: false,
        },
        {
            field: 'student.payment_access.paid',
            headerName: 'Пошлина',
            width: 200,
            sortable: false,
            renderCell: ({ row }) => (
              row?.payment_access?.paid
                ? <Chip label={'Оплачена'} color={'success'}/>
                : <Chip label={'Не оплачена'} color={'error'}/>
            ),
        },
        {
            field: 'user',
            headerName: t('school_page.group_page.students_grid.personal_cabinet'),
            width: 250,
            sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);

                if (row.user_id) {
                    return (
                      <Stack
                        flexDirection={'column'}
                        onClick={() => {handleCopyStudentUserInfoOpen(row.id)}}
                      >
                          <Stack flexDirection={'row'} alignItems={'center'}>
                              <Avatar
                                sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                              >
                                  <Person />
                              </Avatar>
                              <p>{name.fullName}</p>
                              <p style={{color: 'gray', margin: '0 0 0 5px'}}>#{row.user_id} </p>
                          </Stack>
                      </Stack>
                    );
                } else {
                    return (
                      <Button
                        variant={'contained'}
                        sx={{
                            height: 'auto'
                        }}
                        onClick={() => {handleCreateUserOpen(row.id)}}
                      >
                          {t('school_page.group_page.students_grid.create_personal_cabinet')}
                      </Button>
                    )
                }
            }
        },
        {
            field: 'medical_certificate_number',
            headerName: t('school_page.group_page.students_grid.medical_certificate_number'),
            width: 200,
            sortable: false,
        },
        {
            field: 'medical_center_title',
            headerName: t('school_page.group_page.students_grid.medical_center_title'),
            width: 200,
            sortable: false,
        },
        {
            field: 'medical_center_bin',
            headerName: t('school_page.group_page.students_grid.medical_center_bin'),
            width: 200,
            sortable: false,
        },
        {
            field: 'medical_examination_date',
            headerName: t('school_page.group_page.students_grid.medical_examination_date'),
            width: 300,
            sortable: false,
        },
        {
            field: 'created_at',
            headerName: t('school_page.group_page.students_grid.created_at'),
            width: 180,
            sortable: true,
            valueGetter: ({row}) => formatDateTime(row.created_at)
        },
        {
            field: 'actions',
            headerName: t('school_page.group_page.students_grid.actions'),
            width: 500,
            sortable: false,
            renderCell:({row}: GridRowModel<any>) => GridButtons(row)
        }
    ];

    const GridButtons = (row: GridRowModel<any>) => {
        if (!row.medical_certificate_number || !row.medical_center_title || !row.medical_center_bin || !row.medical_examination_date) {
            return (
                <Stack>
                    <Button
                        variant={'contained'}
                        onClick={() => {handleCorrectionOpen(row.id)}}
                        sx={{height: 'auto'}}
                        disabled={correctionStudentList.includes(row.id)}
                    >
                        {t('school_page.group_page.students_grid.add_medical_button')}
                    </Button>
                </Stack>
            )
        } else {
            return (
              <Stack>
                  <Button
                    disabled={true}
                    variant={'contained'}
                    onClick={() => {handleCorrectionOpen(row.id)}}
                    sx={{height: 'auto'}}
                  >
                      {t('school_page.group_page.students_grid.add_medical_button')}
                  </Button>
              </Stack>
            )
        }
    }

    // const handleDelete = (row: GridRowModel<any>) => {
    //     setLoadingIds((prev) => {
    //         return [...prev, row.id];
    //     })
    //
    //     deleteStudentFromGroup(row.id)
    //         .then((res) => {
    //             console.log(res);
    //             snackbar.successMessage('Курсант успешно удален из группы')
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             snackbar.errorMessage(err.response.data.message);
    //         })
    //         .finally(() => {
    //             setLoadingIds((prev) => {
    //                 return prev.filter((id) => {
    //                     if (id === row.id) {
    //                         return false;
    //                     } else {
    //                         return true;
    //                     }
    //                 })
    //             })
    //             fetchGroupInfo();
    //         })
    // }

    return (
        <Box>
            <DataGrid
                columns={(attachmentModuleInfoResponse && attachmentModuleInfoResponse.is_enabled) ? columnsWithPaidAttachment : columns}
                rows={rows}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 30,
                        },
                    },
                }}
                pageSizeOptions={[30]}
                disableRowSelectionOnClick={true}
                autoHeight={true}
                slots={{
                    noRowsOverlay: NoRows
                }}
            />
            {studentId && (
                <CorrectionDialog 
                    groupInfo={groupInfo}
                    open={correctionDialogOpen}
                    onClose={() => {setCorrectionDialogOpen(false)}}
                    studentId={studentId}
                />
            )}
            {studentId && (
                <CreateStudentUserModal
                    open={createUserDialogOpen}
                    studentId={studentId}
                    groupInfo={groupInfo}
                    onClose={() => {setCreateUserDialogOpen(false)}}
                />
            )}
            {studentId && (
                <CopyStudentUserInfoModal
                    open={copyStudentUserInfoModalOpen}
                    studentId={studentId}
                    groupInfo={groupInfo}
                    onClose={() => {setCopyStudentUserInfoModalOpen(false)}}
                />
            )}
        </Box>
    );
};

export default StudentsGrid;
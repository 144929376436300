import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box, Button, Chip, CircularProgress, Stack, Tab, Tabs,
  Typography
} from '@mui/material';
import PageHeader from "../../global/components/PageHeader";
import {useParams} from "react-router-dom";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {wrapperSx} from "../../global/helpers/globalstyles";
import { getSchoolCabinets } from '../actions/api';
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import NoRows from '../../global/components/NoRows';
import AddCabinetModal from '../components/AddCabinetModal';
import {useTranslation} from "react-i18next";
import CustomDataGrid from "../../global/components/CustomDataGrid";

const SchoolCabinetsPage = () => {

  const {id} = useParams();

  const {t} = useTranslation();

  const snackbar = useSnackbarStore((state) => state);
  const [cabinetsList, cabinetsLoading, , fetchCabinets] = useLoadingHook(getSchoolCabinets);
  const [currentTab, setCurrentTab] = useState<string>('true');
  const [addOpen, setAddOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchCabinets(Number(id), currentTab)
  }, []);

  useEffect(() => {
    fetchCabinets(Number(id), currentTab)
  }, [currentTab])

  const columns: GridColDef[] = [
    {field: 'empty', headerName: '', headerClassName: 'datagrid--header', maxWidth: 10, width: 10, sortable: false},
    {field: 'id', headerName: 'ID', width: 75, sortable: true},
    {field: 'address', headerName: t('school_page.cabinets_page.address'), width: 150},
    {field: 'file_url', headerName: t('school_page.cabinets_page.file_url'), width: 250},
    {field: 'approved', headerName: t('school_page.cabinets_page.approved_status'), width: 300,
    renderCell: params => params.row.approved ? (<Chip label={t('school_page.cabinets_page.approved')} color={'primary'}/>) : (<Chip label={t('school_page.cabinets_page.wait')}/>)}
  ];

  const tabs = {
    true: {id: 1, label: t('school_page.cabinets_page.approved_cabinets'), value: 'true'},
    false: {id: 2, label: t('school_page.cabinets_page.wait'), value: 'false'}
  };

  const handleOpen = (st: boolean) => {
    setAddOpen(st);
  };

  const updateCabinets = () => {
    fetchCabinets(Number(id), currentTab);
  }

  if (cabinetsLoading) {
    return (
      <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
        <PageHeader />
        <Box sx={{...wrapperSx}}>

          <Box>
            <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
              <Typography sx={{mt: 3}} variant={'h5'}><b>{t('school_page.cabinets_page.title')}</b></Typography>
              <Typography variant="subtitle2">
                {t('school_page.cabinets_page.subtitle')}
              </Typography>
            </Stack>
          </Box>
            <Stack flexDirection={'row'} justifyContent={'center'}>
              <CircularProgress />
            </Stack>
        </Box>
      </Box>
    );
  }

  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PageHeader />
      <Box sx={{...wrapperSx}}>

        <Box>
          <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
            <Typography sx={{mt: 3}} variant={'h5'}><b>{t('school_page.cabinets_page.title')}</b></Typography>
            <Typography variant="subtitle2">
              {t('school_page.cabinets_page.subtitle')}
            </Typography>
            <Stack flexDirection={'row'} sx={{mt: 2}}>
              <Button
                variant={'contained'}
                onClick={() => {setAddOpen(true)}}
              >
                {t('school_page.cabinets_page.apply-appeal')}
              </Button>
            </Stack>
          </Stack>
        </Box>

        <Tabs
          value={currentTab}
          onChange={(e, newValue) => setCurrentTab(newValue)}
          variant="scrollable"
          scrollButtons={false}
          sx={{ml: 2, mt: 2}}
        >
          {Object.values(tabs).map(tab => <Tab label={tab.label} key={tab.id} value={tab.value}/>)}
        </Tabs>

        <CustomDataGrid
          columns={columns}
          rows={cabinetsList ? cabinetsList : []}
          loading={cabinetsLoading}
          autoHeight={true}
          pageSizeOptions={[20]}
          disableRowSelectionOnClick={true}
          style={{margin: '0'}}
        />
      </Box>
      <AddCabinetModal
        open={addOpen}
        setOpen={handleOpen}
        updateCabinets={updateCabinets}
      />
    </Box>
  );
};

export default SchoolCabinetsPage;
import React, {useEffect, useState} from 'react';
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography
} from "@mui/material";
import { Link, useNavigate, useParams } from 'react-router-dom';
import {useSchoolStore} from "../store/SchoolStore";
import {useUserStore} from "../../global/store/UserStore";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import { getKursantsBySchoolId } from '../actions/api';
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {getName} from "../../global/helpers/text";
import NoRows from "../../global/components/NoRows";
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import CustomDataGrid from "../../global/components/CustomDataGrid";

const SchoolKursants = () => {

  const {id} = useParams();
  const navigate = useNavigate();

  const schoolInfo = useSchoolStore((state) => state);
  const isAdmin = useUserStore((state) => state.isAdmin);
  const snackbar = useSnackbarStore((state) => state);

  const [kursantsList, kursantsLoading, , fetchKursants] = useLoadingHook(getKursantsBySchoolId);

  const columns: GridColDef[] = [
    {field: 'empty', headerName: '', headerClassName: 'datagrid--header', maxWidth: 10, width: 10, sortable: false},
    {field: 'id', headerName: 'ID', width: 110, sortable: true},
    {field: 'fio', headerName: 'ФИО', width: 360, sortable: true,
      renderCell: ({row}) => {
        const name = getName(row);
        return <React.Fragment>
          <Avatar
            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
          >
            {name.partialName}
          </Avatar>
          {name.fullName}
        </React.Fragment>;
      }
    },
    {
      field: 'group.title',
      headerName: 'Учебная группа',
      width: 300,
      sortable: false,
      renderCell: ({row}) => {
        return <React.Fragment>
          <Link
            to={`/groups/${row.group.id}`}
            style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.group.title}
          </Link>
        </React.Fragment>;
      }
    },
    {
      field: 'actions',
      headerName: 'Действия',
      width: 300,
      sortable: false,
      renderCell: ({row}) => {
        return (
          <Button
            onClick={() => {navigate(`/schools/${id}/kursants/${row.id}`)}}
            sx={{height: 'auto'}}
          >
            Перейти к документации
          </Button>
        )
      }
    },
  ];


  useEffect(() => {
    fetchKursants(Number(id));
  }, []);

  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PageHeader/>

      <Box sx={{...wrapperSx}}>
        <Stack flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} sx={{mb: 2}}>
          <Typography sx={{mt: 3}} variant={'h5'}><b>Курсанты учебной организации (студенты)</b></Typography>
          <Typography variant="subtitle1">
            Здесь отображаются все курсанты учебной организации <i><b>{schoolInfo.title}</b></i>
          </Typography>
        </Stack>

        <CustomDataGrid
          columns={columns}
          rows={kursantsList ? kursantsList : []}
          loading={kursantsLoading}
          autoHeight={true}
          pageSizeOptions={[20]}
          disableRowSelectionOnClick={true}
          style={{margin: '0'}}
        />
      </Box>
    </Box>
  );
};

export default SchoolKursants;
import React from 'react';
import {Avatar, Box, Typography} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {formatDate} from "../../global/helpers/text";
import {blue} from "@mui/material/colors";
import { DirectionsCar } from '@mui/icons-material';
import {useTranslation} from "react-i18next";
import CustomDataGrid from "../../global/components/CustomDataGrid";

interface TransportTableProps {
    rows: any[];
    loading: boolean;
    fetchTransport: () => void,
}



const TransportTable = ({rows, loading, fetchTransport} : TransportTableProps) => {

    const {t} = useTranslation();

    const NoRows = () => {
        return (
          <Box sx={{display: 'flex', justifyContent: 'center', p: 5}}>
              <Typography>
                  {t('school_page.transport_page.transport_table.no_data')}
              </Typography>
          </Box>
        )
    }

    const columns: GridColDef[] = [
        {field: 'empty', headerName: '', headerClassName: 'datagrid--header', maxWidth: 10, width: 10, sortable: false},
        {field: 'id', headerName: 'ID', width: 110, sortable: true},
        {field: 'model', headerName: t('school_page.transport_page.transport_table.grid.model'), minWidth: 360, flex: 1, sortable: true,
            renderCell: ({row}) => {
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        <DirectionsCar />
                    </Avatar>
                    {row.brand} {row.model}
                </React.Fragment>;
            }
        },
        {field: 'plate_number', headerName: t('school_page.transport_page.transport_table.grid.plate_number'), minWidth: 160},
        {field: 'category', headerName: t('school_page.transport_page.transport_table.grid.category'), minWidth: 160},
        {field: 'description', headerName: t('school_page.transport_page.transport_table.grid.description'), minWidth: 160},
        {field: 'created_at', headerName: t('school_page.transport_page.transport_table.grid.created'), minWidth: 200, valueFormatter: ({value}) => formatDate(value)},
    ];

    return (
        <Box sx={{mt: 2}}>
            <CustomDataGrid
                columns={columns}
                rows={rows}
                loading={loading}
                autoHeight={true}
                pageSizeOptions={[20]}
                disableRowSelectionOnClick={true}
                style={{margin: '0'}}
            />
        </Box>
    );
};

export default TransportTable;
import {Avatar, Paper, Skeleton, Stack, SxProps, Theme, Typography} from "@mui/material";
import {blue} from "@mui/material/colors";

const paperSx: SxProps<Theme> = {
    padding: 2.5,
    // boxShadow: 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.20) 0px 7px 15px -4px',
    boxShadow: 'none',
    borderRadius: 5,
    p: '36px',
    minWidth: '400px',
  width: '400px',
  height: '285px',
    minHeight: '285px',
    cursor: 'pointer',
    // transitionProperty: 'box-shadow, transform',
    '&:hover': {
        backgroundColor: '#fcfcff',
        // boxShadow: 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.25) 0px 12px 24px -4px',
        transform: 'scale(1.02)'
    }
};

interface CardProps {
    title: string;
    icon: any;
    footer: { title: string, value?: string | number | null, type?: 'Date' }[];
    onClick: () => void;
}

interface CardItemProps {
    title: string;
    value?: string | number | null;
    type?: 'Date';
}

export function Card({title, icon, footer, onClick}: CardProps) {

    const CardItem = ({title, value, type} : CardItemProps) => {
        return (
          <Stack
            sx={{
                width: '156px',
                height: '156px',
                borderRadius: '20px',
                background: '#f5f5f5',
                p: '30px'
            }}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
          >
              <Typography
                fontSize={'48px'}
                letterSpacing={0.17}
                lineHeight={'48px'}
                color={'#05304F'}
              >
                  {value}
              </Typography>
              <Typography>
                  {title}
              </Typography>
          </Stack>
        )
    }

    return (
        <Paper sx={paperSx} onClick={onClick}>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="flex-start" sx={{height: 77}}>
                <Typography fontSize={28} fontWeight={500} lineHeight={1.1} sx={{mt: 0.4, mr: 5}}>{title}</Typography>
                <Avatar
                    sx={{color: '#fff', bgcolor: '#1E9AF3'}}
                >
                    {icon}
                </Avatar>
            </Stack>
            <Stack flexDirection="row" gap={'14px'}>
                {footer.map((item) => {
                    return <CardItem title={item.title} type={item.type} value={item.value} />
                    // if (item.type === 'Date') {
                    //     const isLoading = item.value === undefined || item.value === null;
                    //     return (<Stack sx={{mr: 2.5}} key={item.title}>
                    //             {isLoading &&
                    //                 <Skeleton variant="rounded" width={60} height={20} sx={{mt: 0.5, mb: 0.6}}/>}
                    //             {!isLoading && <Typography fontWeight={600} fontSize={19}>{item.value}</Typography>}
                    //             <Typography fontSize={14} sx={{color: '#5C5C5C'}}>{item.title}</Typography>
                    //         </Stack>
                    //     );
                    // }
                    //
                    // const isLoading = item.value === undefined || item.value === null;
                    // const value = parseInt(String(item.value));
                    // return (<Stack sx={{mr: 2.5}} key={item.title}>
                    //         {isLoading &&
                    //             <Skeleton variant="rounded" width={60} height={20} sx={{mt: 0.5, mb: 0.6}}/>}
                    //         {!isLoading && <Typography fontWeight={600} fontSize={19}>{value}</Typography>}
                    //         <Typography fontSize={14} sx={{color: '#5C5C5C'}}>{item.title}</Typography>
                    //     </Stack>
                    // );
                })}
            </Stack>
        </Paper>);
}

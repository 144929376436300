import React, {useEffect, useState} from 'react';
import {Box, Button, Stack, Typography} from "@mui/material";
import {useUserStore} from "../../global/store/UserStore";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {useNavigate} from "react-router-dom";
import PageHeader from "../../global/components/PageHeader";
import {getUserTickets} from "../actions/api";
import TicketsUserGrid from "../components/TicketsUserGrid";
import {AddCircleOutlined} from "@mui/icons-material";
import {wrapperSx} from "../../global/helpers/globalstyles";

export enum TicketStatus {
    Created = 1,
    AnsweredByAdmin = 2,
    AnsweredByUser = 3,
    Closed = 10
};

const TicketsUserPage = () => {

    const userInfo = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [tickets, setTickets] = useState<any[]>([]);
    const [tab, setTab] = useState<TicketStatus>(0);

    useEffect(() => {
        if (userInfo.iin) {
            fetchTickets();
        } else {
            snackbar.errorMessage('Необходимо авторизоваться для подачи заявки в техническую поддержку');
            navigate('/auth');
        }
    // eslint-disable-next-line
    }, []);

    const fetchTickets = () => {
        setLoading(true);

        getUserTickets()
            .then((res) => {
                console.log(res);
                setTickets(res.data);
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const changeTab = (newTab: number) => {
        setTab(newTab);
    }

    const goToTicket = (ticketId: number) => {
        navigate(`/admin/tickets/${ticketId}`);
    }

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                    <Typography sx={{mt: 3}} variant={'h5'}><b>Служба поддержки</b></Typography>
                </Stack>

                <Button
                    variant={'contained'}
                    sx={{width: '400px', height: '50px', mt: 2}}
                    onClick={() => {navigate('/tickets/new')}}
                >
                        <AddCircleOutlined fontSize={'small'} sx={{mr: 1}}/> Новое обращение
                </Button>

                <TicketsUserGrid
                    openAppeal={goToTicket}
                    rows={tickets}
                    setTab={changeTab}
                    tab={tab}
                    loading={loading}
                />
            </Box>
        </Box>
    );
};

export default TicketsUserPage;
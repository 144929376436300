import { Chip, Skeleton } from '@mui/material';
import {GroupStatus} from "../../actions/group.type";
import {useTranslation} from "react-i18next";

interface Props {
    status?: GroupStatus;
    variant?: 'chip' | 'text';
}



export function GroupStatusChip({status, variant = 'chip'}: Props) {

    const {t} = useTranslation();

    const chips: { [key in GroupStatus]: JSX.Element } = {
        [GroupStatus.New]: <Chip label={t('school_page.group_list.group_status_chip.created')}/>,
        [GroupStatus.Active]: <Chip label={t('school_page.group_list.group_status_chip.active')} color="primary"/>,
        [GroupStatus.Exams]: <Chip label={t('school_page.group_list.group_status_chip.exam')} color="warning"/>,
        [GroupStatus.Finished]: <Chip label={t('school_page.group_list.group_status_chip.finished')} color="success"/>
    };

    const texts: { [key in GroupStatus]: JSX.Element } = {
        [GroupStatus.New]: <>{t('school_page.group_list.group_status_chip.created')}</>,
        [GroupStatus.Active]: <>{t('school_page.group_list.group_status_chip.active')}</>,
        [GroupStatus.Exams]: <>{t('school_page.group_list.group_status_chip.exam')}</>,
        [GroupStatus.Finished]: <>{t('school_page.group_list.group_status_chip.finished')}</>
    };

    if (variant === 'text') {
        if (!status) return <Skeleton width={150} height={35}/>;
        return texts[status];
    } else {
        if (!status) return <Skeleton width={100} height={30}/>;
        return chips[status];
    }
}
import {
    Box,
    Button, CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Stack,
    Table,
    TableBody, TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import LoadingButton from '../../global/components/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { IRegisterSchool, IRegisterUser, IRegisterAttributes } from '../dto/AuthDto';
import {useTranslation} from "react-i18next";

interface FourthStepProps {
    signString: string,
    setSignString: (signStr: string) => void,
    registerLoading: boolean,
    finishReg: () => void,
    nextStep: (step: number) => void,
    registerFinished: boolean,
    schoolForm: IRegisterSchool,
    userForm: IRegisterUser,
    attributesForm: IRegisterAttributes // Добавил props для attributesForm
}

const FourthStep = ({ signString, setSignString, registerLoading, finishReg, nextStep, registerFinished, schoolForm, userForm, attributesForm }: FourthStepProps) => {

    const navigate = useNavigate();

    const { t } = useTranslation();

    const snackbar = useSnackbarStore((store) => store);
    const [ws, setWs] = useState<WebSocket | null>(null);
    const [messages, setMessages] = useState<string[]>([]);
    const [callback, setCallback] = useState<string | null>(null);

    const connectNCAWebSocket = () => {
        const websocket = new WebSocket('wss://127.0.0.1:13579/');

        websocket.onopen = () => {
            snackbar.infoMessage('Соединение с NCALayer успешно подключено');
        }

        websocket.onmessage = (event) => {
            setMessages(prevMessages => [...prevMessages, event.data]);

            const result = JSON.parse(event.data);

            if (result) {
                const code = result.code;
                const message = result.message;
                const responseObject = result.responseObject;

                if (responseObject) {
                    setSignString(responseObject)
                }
            }
        }

        websocket.onerror = (event) => {
            snackbar.errorMessage('Не удалось установить соединение с NCALayer. Включите NCALayer и попробуйте попытку')
            if (window.confirm('Не удалось установить соединение с NCALayer. Нажмите OK для повторной попытки')) {
                connectNCAWebSocket();
            }
        }

        websocket.onclose = () => {
            console.log('WebSocket connection closed');
            setWs(null); // Обновляем состояние, указывая на закрытие соединения
        };

        setWs(websocket ? websocket : null); // Обновляем состояние с новым экземпляром WebSocket
    };

    useEffect(() => {
        connectNCAWebSocket();
    }, [])

    function signXml(storageName : string, keyType : string, xmlToSign : string, callBack : string) {
        var signXml = {
            "module": "kz.gov.pki.knca.commonUtils",
            "method": "signXml",
            "args": [storageName, keyType, xmlToSign, "", ""]
        };
        setCallback(callBack);
        ws?.send(JSON.stringify(signXml));
    };

    const xmsSignCall = (xml: any) => {
        const storageType = "PKCS12";
        const xmlToSign = xml;

        signXml(storageType, "SIGNATURE", xmlToSign, "signXmlBack");
    };

    const returnToSecondStep = () => {
        setSignString('');
        nextStep(1);
    };

    const schoolFieldNames = {
        bin: t('auth.fourth-step.bin'),
        title: t('auth.fourth-step.title'),
        director: t('auth.fourth-step.director'),
        director_iin: t('auth.fourth-step.director_iin'),
        email: t('auth.fourth-step.email'),
        region: t('auth.fourth-step.region'),
        locality: t('auth.fourth-step.locality'),
        address: t('auth.fourth-step.address'),
        phone1: t('auth.fourth-step.phone1'),
        phone2: t('auth.fourth-step.phone2'),
    };

    const userFieldNames: {[key in keyof IRegisterUser]?: string} = {
        iin: t('auth.fourth-step.iin'),
        first_name: t('auth.fourth-step.last_name'),
        last_name: t('auth.fourth-step.first_name'),
        patronymic: t('auth.fourth-step.patronymic'),
        email: t('auth.fourth-step.email'),
        phone: t('auth.fourth-step.phone'),
    };

    const InfoTable: React.FC<{ data: any; fieldNames: { [key: string]: string } }> = ({ data, fieldNames }) => (
      <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                  {Object.keys(fieldNames).map((key) => (
                    <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                            {fieldNames[key]}
                        </TableCell>
                        <TableCell align="right">{Array.isArray(data[key]) ? data[key].join(', ') : data[key]}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
          </Table>
      </TableContainer>
    );

    const AttributesTable: React.FC<{ data: IRegisterAttributes }> = ({ data }) => (
      <TableContainer component={Paper} sx={{ maxHeight: 400, overflowY: 'auto' }}>
          <Table sx={{ minWidth: 650 }} aria-label="attributes table">
              <TableBody>
                  <TableRow>
                      <TableCell>{t('auth.fourth-step.teachers')}</TableCell>
                      <TableCell align="right">
                          {data.teachers && data.teachers.map((teacher, index) => (
                            <div key={index}>
                                <Typography>{t('auth.fourth-step.iin')}: {teacher.iin}</Typography>
                                <Typography>{t('auth.fourth-step.qualification_certificate')}: {teacher.qualification_certificate}</Typography>
                                <br />
                            </div>
                          ))}
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell>{t('auth.fourth-step.drivers')}</TableCell>
                      <TableCell align="right">
                          {data.drivers && data.drivers.map((driver, index) => (
                            <div key={index}>
                                <Typography>{t('auth.fourth-step.iin')}: {driver.iin}</Typography>
                                <Typography>{t('auth.fourth-step.qualification_certificate')}: {driver.qualification_certificate}</Typography>
                                <br />
                            </div>
                          ))}
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell>{t('auth.fourth-step.masters')}</TableCell>
                      <TableCell align="right">
                          {data.masters && data.masters.map((master, index) => (
                            <div key={index}>
                                <Typography>{t('auth.fourth-step.iin')}: {master.iin}</Typography>
                                <Typography>{t('auth.fourth-step.qualification_certificate')}: {master.qualification_certificate}</Typography>
                                <br />
                            </div>
                          ))}
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell>{t('auth.fourth-step.transports')}</TableCell>
                      <TableCell align="right">
                          {data.transports && data.transports.map((transport, index) => (
                            <div key={index}>
                                <Typography>{t('auth.fourth-step.plate_number')}: {transport.plate_number}</Typography>
                                <Typography>{t('auth.fourth-step.description')}: {transport.description}</Typography>
                                <br />
                            </div>
                          ))}
                      </TableCell>
                  </TableRow>
              </TableBody>
          </Table>
      </TableContainer>
    );

    return (
        <Box className={'register-form'} flexDirection={'column'} alignItems={'center'}>

            <Stack flexDirection={'row'} gap={'100px'} sx={{mt: 5}}>
                <Stack flexDirection={'column'} gap={2}>
                    <h2>{t('auth.fourth-step.school-info')}</h2>
                    <InfoTable data={schoolForm} fieldNames={schoolFieldNames} />
                </Stack>

                <Stack flexDirection={'column'} gap={2}>
                    <h2>{t('auth.fourth-step.user-info')}</h2>
                    <InfoTable data={userForm} fieldNames={userFieldNames} />
                </Stack>
            </Stack>

            {/* Новый раздел для AttributesForm */}
            <Stack flexDirection={'column'} gap={2} sx={{ mt: 5 }}>
                <h2>{t('auth.fourth-step.employees-transports-info')}</h2>
                <AttributesTable data={attributesForm} />
            </Stack>

            <Stack flexDirection={'row'} justifyContent={'center'} gap={'20px'} sx={{mt: 2}}>
                <LoadingButton
                    onClick={() => {returnToSecondStep()}}
                    color={'info'}
                    variant={'outlined'}
                    sx={{width: '207px', height: '56px'}}
                    loading={registerLoading}
                >
                    {t('auth.fourth-step.back')}
                </LoadingButton>
                <LoadingButton
                    onClick={() => {ws ? (!signString ? xmsSignCall('<signData>Согласен с отправляемыми данными</signData>') : snackbar.errorMessage('Данные уже подписаны')) : connectNCAWebSocket()}}
                    variant={'contained'}
                    sx={{width: '207px', height: '56px'}}
                    loading={registerLoading}
                    disabled={!ws}
                >
                    {t('auth.fourth-step.sign')}
                </LoadingButton>
                {signString && (<LoadingButton
                    onClick={() => {finishReg()}}
                    variant={'contained'}
                    sx={{width: '207px', height: '56px'}}
                    loading={registerLoading}
                    color={'secondary'}
                >
                    {t('auth.fourth-step.send')}
                </LoadingButton>)}
            </Stack>

        </Box>
    );
};

export default FourthStep;

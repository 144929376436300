import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { useUserStore } from '../../global/store/UserStore';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { useNavigate } from 'react-router-dom';
import {apiGetSchoolsStatistics} from '../actions/api';
import { DirectionsCar, ExpandMoreOutlined } from '@mui/icons-material';
import useLoadingHook from "../../global/hooks/UseLoadingHook";

const regionsArray = [
    "Астана", "Алматы", "Шымкент",
    "Абайская область", "Акмолинская область", "Актюбинская область",
    "Алматинская область", "Атырауская область", "Восточно-Казахстанская область",
    "Жамбылская область", "Жетысуская область", "Западно-Казахстанская область",
    "Карагандинская область", "Костанайская область", "Кызылординская область",
    "Мангистауская область", "Павлодарская область", "Северо-Казахстанская область",
    "Туркестанская область", "Улытауская область"
]

const SchoolsStatistic = () => {

    const [statisticsResponse, statisticsLoading, statisticsError, fetchStatistics] = useLoadingHook(apiGetSchoolsStatistics);

    const isAdmin = useUserStore((state) => state.isAdmin);
    const currentUser = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();
    const [statistic, setStatistic] = useState<any>({
        regions: {},
        total: 0,
        today: 0
    });

    useEffect(() => {
        if (!isAdmin) {
            snackbar.errorMessage('У вас нет прав доступа к данной странице');
            navigate('/');
        } else {
            fetchStatistics();
        }
    }, []);

    useEffect(() => {
        if (statisticsError) {
            snackbar.errorMessage(statisticsError.response.data.message ? statisticsError.response.data.message : 'Произошла непредвиденная ошибка');
        }

        if (statisticsResponse) {
            setStatistic(statisticsResponse);
        }
    }, [statisticsResponse, statisticsError]);

    return (
        <Accordion sx={{mt: 2}}>
            <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
            >
                    <Stack flexDirection="row" alignItems="center" sx={{mt: 1, mb: 1}}>
                        <Avatar
                            sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2, width: 50, height: 50}}
                        >
                            <DirectionsCar fontSize="medium"/>
                        </Avatar>
                        <Stack>
                            <Typography variant="h5">Статистика по регистрации</Typography>
                        </Stack>
                    </Stack>
            </AccordionSummary>
            <AccordionDetails>
                {statisticsLoading && <CircularProgress />}

                {!statisticsLoading && (<>
                    <Box flexDirection={'row'}>
                        <Typography variant={'h6'}>Всего зарегистрировано учебных организаций: <b>{statistic.total}</b></Typography>
                    </Box>
                    <Divider sx={{my: 1}}/>
                    <Box flexDirection={'column'}>
                        {regionsArray.map((region) => {
                            return (
                                <Typography fontSize={'14px'} key={region}>{region}: {statistic.regions[region]}</Typography>
                            )
                        })}
                    </Box>
                    <Divider sx={{my: 1}}/>
                    <Box flexDirection={'row'}>
                        <Typography variant={'h6'}>За сегодня зарегистрировано учебных организаций: <b>{statistic.today}</b></Typography>
                    </Box>
                </>)}
            </AccordionDetails>
        </Accordion>
    );
};

export default SchoolsStatistic;

import React, {useEffect, useState} from 'react';
import {Avatar, Box, Chip, CircularProgress, Stack, Switch, Tooltip, Typography} from "@mui/material";
import {useUserStore} from "../../global/store/UserStore";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {Link, useNavigate} from "react-router-dom";
import {
  apiChangeFeatureEnable,
  apiGetFeatureToggles, apiGetSchoolsWithFeatureToggles,
} from "../actions/api";
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {FeatureToggleType} from "../../global/actions/types.api";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {DirectionsCar} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import NoRows from "../../global/components/NoRows";
import InfoIcon from "@mui/icons-material/Info";

export enum AppealStatus {
  Created = 1,
  Approved = 2,
  Closed = 3,
  Denied = 10
}

const SuperAdminModulesPage = () => {

  const {t} = useTranslation();

  const snackbar = useSnackbarStore((state) => state);
  const navigate = useNavigate();

  const errorMessage = useSnackbarStore((state) => state.errorMessage);
  const isSuperAdmin = useUserStore((state) => state.isSuperAdmin);

  const [featuresTogglesResponse, featuresTogglesLoading, featuresTogglesError, requestFeatureToggles] = useLoadingHook<FeatureToggleType[]>(apiGetFeatureToggles);
  const [changeFeatureEnabledResponse, changeFeatureEnabledLoading, changeFeatureEnabledError, changeFeatureEnabled] = useLoadingHook<boolean>(apiChangeFeatureEnable);

  const [schoolsList, schoolsListLoading, schoolsListError, requestSchoolsList] = useLoadingHook(apiGetSchoolsWithFeatureToggles);

  useEffect(() => {
    if (!isSuperAdmin) {
      errorMessage('У вас нет права доступа к данной странице');
      navigate('/')
    } else {
      requestFeatureToggles()
        .then(() => {
          requestSchoolsList();
        })
    }
  }, []);

  const handleChangeEnabled = (checked: boolean, moduleName: string) => {
    changeFeatureEnabled({
      module_name: moduleName,
      is_enabled: checked,
    })
  };

  useEffect(() => {
    if (changeFeatureEnabledResponse) {
      requestFeatureToggles();
    }

    if (changeFeatureEnabledError) {
      snackbar.errorMessage(changeFeatureEnabledError?.response?.data?.message ?? 'Произошла непредвиденная ошибка');
    }
  }, [changeFeatureEnabledResponse, changeFeatureEnabledError]);

  useEffect(() => {
    if (featuresTogglesError) {
      snackbar.errorMessage(featuresTogglesError?.response?.data?.message ?? 'Произошла непредвиденная ошибка');
    }
  }, [featuresTogglesError]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'datagrid--header',
      width: 90,
      sortable: true,
    },
    {
      field: 'bin',
      headerName: t('register.school_list_component.grid.bin'),
      headerClassName: 'datagrid--header',
      width: 200,
      sortable: false,
    },
    {
      field: 'title',
      headerName: t('register.school_list_component.grid.title'),
      headerClassName: 'datagrid--header',
      width: 400,
      sortable: false,
      renderCell: (params) => {
        return <React.Fragment>
          <Link
            to={`/schools/${params.row.id}`}
            style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Avatar
              sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2}}
            >
              <DirectionsCar fontSize="small"/>
            </Avatar>
            {params.value}
          </Link>
        </React.Fragment>;
      }
    },
    {
      field: 'actions',
      headerName: 'Информация о модулях',
      width: 350,
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            sx={{
              width: '100%',
              py: 1
            }}
            gap={1}
          >
            {params.row.features.length === 0 && (
              <Typography fontSize={'14px'}>
                Нет данных
              </Typography>
            )}
            {params.row.features.length > 0 && params.row.features?.map((feature: any) => {
              return (
                <Stack
                  flexDirection={'column'}
                  gap={1}
                  alignItems={'center'}
                >
                  <Typography fontSize={'14px'}>{feature.is_enabled}</Typography>
                  <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                  >
                    <Typography fontSize={'14px'}>
                      Откл.
                    </Typography>
                    <Switch checked={feature.is_enabled} onChange={(e) => {}}/>
                    <Typography fontSize={'14px'}>
                      Вкл.
                    </Typography>
                  </Stack>
                </Stack>
              )
            })}
            <Tooltip title={'Для включения/выключения модуля для определенной школы необходимо перейти на страницу автошколы'} sx={{ml: 2}} color={'gray'}>
                <InfoIcon/>
            </Tooltip>
          </Stack>
        )
      }
    }
  ];

  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PageHeader />
      <Box sx={{...wrapperSx}}>
        <Stack
          direction={'column'}
          width={'100%'}
          sx={{
            mt: '38px',
            p: '52px',
            borderRadius: '24px',
            background: '#F5F5F5',
          }}
        >
          <Typography
            variant={'h5'}
            sx={{
              mt: '32px'
            }}
          >
            Модули системы:
          </Typography>
          {featuresTogglesLoading && <CircularProgress />}
          {featuresTogglesResponse && (
            <Stack
              flexDirection={'row'}
              flexWrap={'wrap'}
              sx={{
                py: '32px',
                gap: '24px'
              }}
            >
              {featuresTogglesResponse.map((feature) => {
                return (
                  <Stack
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{
                      width: '300px',
                      height: 'auto',
                      minHeight: '150px',
                      borderRadius: '16px',
                      backgroundColor: '#FFFFFF',
                      py: '20px',
                      px: '16px',
                      gap: '16px'
                    }}
                    key={feature.id}
                  >
                    <Typography
                      fontSize={'14px'}
                      color={'gray'}
                    >
                      #{feature.module_name}
                    </Typography>
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'bold'}
                    >
                      Состояние:
                    </Typography>
                    <Typography
                      fontSize={'14px'}
                      sx={{
                        mt: '-16px'
                      }}
                    >
                      <Chip
                        label={feature.is_enabled ? 'Включен' : 'Отключен'}
                        color={feature.is_enabled ? 'success' : 'error'}
                        sx={{
                          mt: 1
                        }}
                      />
                    </Typography>
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'bold'}
                    >
                      Описание модуля:
                    </Typography>
                    <Typography
                      fontSize={'14px'}
                      sx={{
                        mt: '-16px'
                      }}
                    >
                      {feature.description}
                    </Typography>
                    <Stack
                      flexDirection={'row'}
                      alignItems={'center'}
                    >
                      <Typography>
                        Откл.
                      </Typography>
                      <Switch checked={feature.is_enabled} onChange={(e) => handleChangeEnabled(e.target.checked, feature.module_name)}/>
                      <Typography>
                        Вкл.
                      </Typography>
                    </Stack>
                  </Stack>
                )
              })}
            </Stack>
          )}
        </Stack>

        <Stack
          direction={'column'}
          width={'100%'}
          sx={{
            mt: '38px',
            p: '52px',
            borderRadius: '24px',
            background: '#F5F5F5',
          }}
        >
          <Typography
            variant={'h5'}
            sx={{
              mt: '32px'
            }}
          >
            Управление теоретическими экзаменами по школам
          </Typography>
          {schoolsListLoading && <CircularProgress />}
          {schoolsList && (
            <Stack
              flexDirection={'row'}
              flexWrap={'wrap'}
              sx={{
                py: '32px',
                gap: '24px',
                width: '1200px'
              }}
            >
              <DataGrid
                columns={columns}
                rows={schoolsList ? schoolsList : []}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 20,
                    },
                  },
                }}
                getRowHeight={() => 'auto'}
                // loading={loading}
                autoHeight={true}
                pageSizeOptions={[20]}
                disableRowSelectionOnClick={true}
                // style={{margin: '0'}}
                slots={{
                  noRowsOverlay: NoRows
                }}
              />
            </Stack>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default SuperAdminModulesPage;
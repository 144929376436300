import {Box, Button, Chip, CircularProgress, InputBase, Stack, Typography, useTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import PageHeader from "../../global/components/PageHeader";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import useMediaQuery from "@mui/material/useMediaQuery";
import {useUserStore} from "../../global/store/UserStore";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {apiAddNews} from "../actions/api";
import LoadingButton from "../../global/components/LoadingButton";
import {useDropzone} from "react-dropzone";
import {formatDate} from "../../global/helpers/text";
import {useTranslation} from "react-i18next"; // Импорт темы

const AdminNewsRedactor = () => {

  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const {t, i18n} = useTranslation();

  const [submitNewsResponse, submitNewsLoading, submitNewsError, requestSubmitNews] = useLoadingHook(apiAddNews);

  const currentUser = useUserStore((state) => state);

  const [newsForm, setNewsForm] = useState({
    title_ru: '',
    title_kz: '',
    description_ru: '',
    description_kz: '',
    content_ru: '',
    content_kz: '',
    thumbnail: ''
  });

  const onDropRejected = (fileRejections: any) => {
    fileRejections.forEach((rejection: any) => {
      rejection.errors.forEach(() => {
        toast.error('Данный файл не подходит по размеру или формату')
      });
    });
  };

  // Обработка принятого файла
  const onDropAccepted = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event: any) => {
      const base64String = event.target.result;
      setNewsForm((prev) => {
        return {
          ...prev,
          thumbnail: base64String
        }
      });
    };
    reader.onerror = (error) => {
      toast.error('Не удалось прочитать файл');
    };

    reader.readAsDataURL(file);
  };

  const {
    acceptedFiles, isDragActive, open, getRootProps,
    getInputProps, isFocused, isDragAccept, isDragReject
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg']
    },
    maxFiles: 1,
    maxSize: 5242880,
    onDropAccepted,
    onDropRejected
  });

  const Font = ReactQuill.Quill.import('formats/font');
  Font.whitelist = ['sans-serif', 'serif', 'monospace'];
  ReactQuill.Quill.register(Font, true);

  const modules = {
    toolbar: [
      // Форматирование текста
      [{ font: Font.whitelist }], // Выбор шрифта
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // Заголовки
      ['bold', 'italic', 'underline', 'strike'], // Жирный, курсив, подчеркивание, зачеркнутый текст

      // Списки
      [{ list: 'ordered' }, { list: 'bullet' }], // Нумерованные и маркированные списки
      [{ indent: '-1' }, { indent: '+1' }], // Уменьшение/увеличение отступов

      // Выравнивание текста
      [{ align: [] }], // Выравнивание (лево, центр, право, по ширине)

      // Цвет текста и фона
      [{ color: [] }, { background: [] }], // Цвет текста и фона

      // Вставка контента
      ['blockquote', 'code-block'], // Цитата, блок кода
      ['link', 'image', 'video'], // Ссылки, изображения, видео

      // Очистка форматирования
      ['clean'], // Удаление форматирования
    ],
  };

  const handleNewsContentChange = (name: string, value: string) => {
    setNewsForm((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  };

  useEffect(() => {
    if (!currentUser.isAdmin && !currentUser.isSuperAdmin) {
      navigate('/');
      toast.error('У вас нет доступа к данной странице')
    }
  }, []);

  const handleSubmit = () => {
    if (!newsForm.title_ru) {
      toast.error('Необходимо добавить заголовок на русском языке')
      return;
    }

    if (!newsForm.title_kz) {
      toast.error('Необходимо добавить заголовок на казахском языке')
      return;
    }

    if (!newsForm.description_ru) {
      toast.error('Необходимо добавить краткое описание на русском языке')
      return;
    }

    if (!newsForm.description_kz) {
      toast.error('Необходимо добавить краткое описание на казахском языке')
      return;
    }

    if (!newsForm.content_ru) {
      toast.error('Необходимо добавить контент на русском языке')
      return;
    }

    if (!newsForm.content_kz) {
      toast.error('Необходимо добавить контент на казахском языке')
      return;
    }

    requestSubmitNews(newsForm);
  };

  useEffect(() => {
    if (submitNewsResponse) {
      toast.success('Новость успешно добавлена');
      cleanForm();
    }
  }, [submitNewsResponse]);

  useEffect(() => {
    if (submitNewsError) {
      toast.error(submitNewsError?.response?.data?.message ?? 'Произошла непредвиденная ошибка');
    }
  }, [submitNewsError]);

  const cleanForm = () => {
    setNewsForm({
      title_ru: '',
      title_kz: '',
      description_ru: '',
      description_kz: '',
      content_ru: '',
      content_kz: '',
      thumbnail: ''
    })
  }

  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PageHeader />
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'16px'}
        sx={{
          mx: 4,
          px: 2,
          pt: 2,
          pb: 15,
          borderRadius: '16px',
          background: '#fff',
          height: 'fit-content'
        }}
      >
        <Stack
          flexDirection={isMobile ? 'column' : 'row'}
          gap={'16px'}
        >
          <Stack
            flexDirection={'column'}
            gap={'16px'}
            sx={{
              width: isMobile ? '100%' : '45%',
            }}
          >
            <Typography
              variant={'h6'}
            >
              Заголовок новости на русском языке
            </Typography>
            <InputBase
              type={'text'}
              name={'title_ru'}
              value={newsForm.title_ru}
              onChange={(e) => {handleNewsContentChange('title_ru', e.target.value)}}
              placeholder={'Заголовок'}
              sx={{
                border: '1px solid #ccc',
                borderRadius: '0'
              }}
            />
          </Stack>

          <Stack
            flexDirection={'column'}
            gap={'16px'}
            sx={{
              width: isMobile ? '100%' : '45%',
            }}
          >
            <Typography
              variant={'h6'}
            >
              Заголовок новости на казахском языке
            </Typography>
            <InputBase
              type={'text'}
              name={'title_kz'}
              value={newsForm.title_kz}
              onChange={(e) => {handleNewsContentChange('title_kz', e.target.value)}}
              placeholder={'Заголовок'}
              sx={{
                border: '1px solid #ccc',
                borderRadius: '0'
              }}
            />
          </Stack>
        </Stack>

        <Stack
          flexDirection={isMobile ? 'column' : 'row'}
          gap={'16px'}
        >
          <Stack
            flexDirection={'column'}
            gap={'16px'}
            sx={{
              width: isMobile ? '100%' : '45%',
            }}
          >
            <Typography
              variant={'h6'}
            >
              Краткое описание новости на русском языке
            </Typography>
            <InputBase
              type={'text'}
              name={'description_ru'}
              value={newsForm.description_ru}
              onChange={(e) => {handleNewsContentChange('description_ru', e.target.value)}}
              placeholder={'Краткое описание новости'}
              sx={{
                border: '1px solid #ccc',
                borderRadius: '0'
              }}
            />
          </Stack>

          <Stack
            flexDirection={'column'}
            gap={'16px'}
            sx={{
              width: isMobile ? '100%' : '45%',
            }}
          >
            <Typography
              variant={'h6'}
            >
              Краткое описание новости на казахском языке
            </Typography>
            <InputBase
              type={'text'}
              name={'description_kz'}
              value={newsForm.description_kz}
              onChange={(e) => {handleNewsContentChange('description_kz', e.target.value)}}
              placeholder={'Заголовок'}
              sx={{
                border: '1px solid #ccc',
                borderRadius: '0'
              }}
            />
          </Stack>
        </Stack>


        <Stack
          flexDirection={isMobile ? 'column' : 'row'}
          gap={'16px'}
        >
          <Stack
            flexDirection={'column'}
            gap={'16px'}
            sx={{
              width: isMobile ? '100%' : '45%',
              minHeight: isMobile ? '0' : '300px'
            }}
          >
            <Typography
              variant={'h6'}
            >
              Содержимое новости на русском языке
            </Typography>
            <ReactQuill
              value={newsForm.content_ru}
              onChange={(value) => {handleNewsContentChange('content_ru', value)}}
              style={{
                minHeight: isMobile ? '0' : '300px',
              }}
              modules={modules}
            />
          </Stack>

          <Stack
            flexDirection={'column'}
            gap={'16px'}
            sx={{
              width: isMobile ? '100%' : '45%',
              minHeight: isMobile ? '0' : '300px'
            }}
          >
            <Typography
              variant={'h6'}
            >
              Содержимое новости на казахском языке
            </Typography>
            <ReactQuill
              value={newsForm.content_kz}
              onChange={(value) => {handleNewsContentChange('content_kz', value)}}
              style={{
                minHeight: isMobile ? '0' : '300px'
              }}
              modules={modules}
            />
          </Stack>
        </Stack>


        <Box
          {...getRootProps({
            className: "dropzone",
            style: {
              marginTop: '50px',
              padding: '20px',
              borderWidth: '2px',
              borderRadius: 2,
              borderColor: isFocused ? '#2196f3' : isDragAccept ? '#00e676' : isDragReject ? '#ff1744' : '#eeeeee',
              borderStyle: 'dashed',
              backgroundColor: '#fafafa',
            },
          })}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
          }}
        >
          <input {...getInputProps()} />
          <Box className="text-center"
               display="flex"
               flexDirection={"column"}
               gap={"10px"}
               alignItems="center"
          >
            {isDragActive ? (
              <p className="dropzone-content">
                Перетащите файл сюда...
              </p>
            ) : (
              <p className="dropzone-content"
                 style={{
                   textAlign: "center"
                 }}
              >
                Перетащите сюда изображение или нажмите кнопку ниже для выбора файла
              </p>
            )}
            <Button
              type="button"
              onClick={open}
              className="btn"
              variant={"contained"}
              sx={{width: "200px"}}
            >
              Выбрать файл
            </Button>
          </Box>
          <aside style={{
            display: 'flex !important',
            flexWrap: 'wrap',
            width: 'auto',
            padding: '10px 20px',
          }}>
            {newsForm.thumbnail && (
              <img
                src={newsForm.thumbnail}
                alt="Предпросмотр"
                style={{ width: 200, height: 'auto', marginTop: 10, borderRadius: 4 }}
              />
            )}
          </aside>
        </Box>

        <Stack
          flexDirection={'column'}
          gap={'16px'}
          sx={{
            border: '1px solid gray',
            borderRadius: '16px',
            p: 2
          }}
        >
          <Typography
            variant={'h6'}
          >
            Предпросмотр новости
          </Typography>

          {newsForm && (
            <img
              src={newsForm?.thumbnail}
              style={{
                width: isMobile ? '100%' : '800px',
                height: isMobile ? 'auto' : '400px',
                objectFit: 'cover', // Сохраняет пропорции и обрезает изображение, если оно превышает размеры
                objectPosition: 'center', // Центрирует изображение в рамке
                borderRadius: '16px'
              }}
            />
          )}
          {newsForm && (
            <Typography
              variant={'h5'}
              fontWeight={'bold'}
            >
              {i18n.language === 'ru' ? newsForm?.title_ru : newsForm?.title_kz}
            </Typography>
          )}
          {newsForm && (
            <Stack
              sx={{
                fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                whiteSpace: 'pre-wrap',
                'ul, ol': {
                  paddingLeft: '20px'
                }
              }}
              className={'ql-editor'}
              dangerouslySetInnerHTML={{ __html: i18n.language === 'ru' ? newsForm.content_ru : newsForm.content_kz}}
            />
          )}
          {newsForm && (
            <Chip label={`${t('news-page.created_at')} ${formatDate(new Date())}`} sx={{width: 'fit-content'}}/>
          )}
        </Stack>

        <LoadingButton
          variant={'contained'}
          onClick={() => {handleSubmit()}}
          sx={{
            mt: '50px',
            width: 'fit-content'
          }}
          loading={submitNewsLoading}
        >
          Опубликовать
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default AdminNewsRedactor;
import {
    Button,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    InputBase,
    Stack,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { useNavigate } from 'react-router-dom';
import LoadingButton from "../../global/components/LoadingButton";
import {Download} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

interface RegisterStudentComponentProps {
    userForm: any,
    handleUserChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    registerLoading: boolean,
    finishReg: () => void,
}

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };

export const RegisterStudentComponent = ({userForm, handleUserChange, registerLoading, finishReg} : RegisterStudentComponentProps) => {

    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();

    const { t } = useTranslation();

    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [hidden, setHidden] = useState<boolean>(true);
    const [offerAccepted, setOfferAccepted] = useState<boolean>(false);
    const [offerOpen, setOfferOpen] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setConfirmPassword(e.target.value);
    };

    const handleFinish = () => {
        if (offerAccepted) {
            setOfferOpen(false);
            finishReg();
        }
    }
    const handleOpenOffer = () => {

        if (!userForm.iin || !userForm.first_name || !userForm.last_name || !userForm.email || !userForm.phone) {
            snackbar.errorMessage(t('auth.register-student.required-data'));
            return;
        }

        if (!userForm.password || !confirmPassword) {
            snackbar.errorMessage(t('auth.register-student.required-passwords'));
            return;
        }

        if (/^[a-zA-Z0-9$#!@%]*$/.test(userForm.password)) {
            setOfferOpen(true);
        } else if (confirmPassword !== userForm.password) {
            snackbar.errorMessage(t('auth.register-student.mismatch-passwords'))
            return;
        } else {
            snackbar.errorMessage(t('auth.register-student.invalid-password'));
            return;
        }
    }

    return (
        <Stack flexDirection={'column'}>
            <Stack
                flexDirection={'row'}
                sx={{
                    p: '36px',
                    my: 2,
                    background: '#FFF',
                    borderRadius: '16px',
                }}
                gap={'36px'}
            >
                <Stack flexDirection={'column'} sx={{width: '50%'}} gap={'16px'}>
                    <Typography sx={{background: '#F8F9FB', p: '8px 16px'}} ><b>{t('auth.register-student.user-info')}</b></Typography>

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.register-student.iin')}
                        value={userForm.iin}
                        name={'iin'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.register-student.last_name')}
                        value={userForm.last_name}
                        name={'last_name'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.register-student.first_name')}
                        value={userForm.first_name}
                        name={'first_name'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.register-student.patronymic')}
                        value={userForm.patronymic}
                        name={'patronymic'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={false}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.register-student.email')}
                        value={userForm.email}
                        name={'email'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.register-student.phone')}
                        value={userForm.phone}
                        name={'phone'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />
                </Stack>

                <Stack flexDirection={'column'} sx={{width: '50%'}} gap={'16px'}>
                    <Typography sx={{background: '#F8F9FB', p: '8px 16px'}} ><b>{t('auth.register-student.password-info')}</b></Typography>

                    <InputBase
                        type={hidden ? 'password' : 'text'}
                        placeholder={t('auth.register-student.password')}
                        value={userForm.password}
                        name={'password'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />
                    <InputBase
                        type={hidden ? 'password' : 'text'}
                        placeholder={t('auth.register-student.repeat-password')}
                        value={confirmPassword}
                        name={'password'}
                        onChange={handleChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    {hidden && <Button
                        variant={'outlined'}
                        color={'info'}
                        sx={{
                            width: 'fit-content'
                        }}
                        onClick={() => {setHidden(false)}}
                    >
                        {t('auth.register-student.show')}
                    </Button>}
                    {!hidden && <Button
                        variant={'outlined'}
                        color={'info'}
                        sx={{
                            width: 'fit-content'
                        }}
                        onClick={() => {setHidden(true)}}
                    >
                        {t('auth.register-student.hide')}
                    </Button>}
                </Stack>

            </Stack>
            <Stack flexDirection={'row'} justifyContent={'center'} gap={'20px'} sx={{mt: '36px'}}>
                <LoadingButton
                    onClick={() => {handleOpenOffer()}}
                    variant={'contained'}
                    sx={{width: '207px', height: '56px'}}
                    loading={registerLoading}
                >
                    {t('auth.register-student.register')}
                </LoadingButton>

            </Stack>

            <Dialog
                open={offerOpen}
                onClose={() => {setOfferOpen(false)}}
                maxWidth={'xl'}
            >
                <DialogTitle>
                    <b>{t('auth.register-student.offer-title')}</b>
                </DialogTitle>
                <DialogContent sx={{fontFamily: 'sans-serif'}}>
                  <a href="/UserOffer.pdf" download="Договор оферты" style={{display: 'block', width: 'fit-content', textDecoration: 'none', color: 'black'}}>
                    <Stack
                      display={'flex'}
                      flexDirection={'column'}
                      sx={{
                        width: '300px',
                        height: 'auto',
                        minHeight: '150px',
                        borderRadius: '16px',
                        backgroundColor: '#fafafa',
                        py: '20px',
                        px: '16px',
                        gap: '16px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                        <Typography sx={{textAlign: 'center'}}>{t('auth.register-student.download-offer')}</Typography>
                        <Download fontSize={'large'}/>
                    </Stack>
                  </a>
                </DialogContent>
                <DialogActions sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 2}}>
                    <Button
                        onClick={() => {
                            setOfferAccepted(false);
                            setOfferOpen(false);
                        }}
                        variant={'contained'}
                        color={'error'}
                        disabled={registerLoading}
                    >
                        {t('auth.register-student.refuse')}
                    </Button>

                    {!registerLoading && (<Button
                        onClick={() => {
                            setOfferAccepted(true);
                            handleFinish();
                        }}
                        variant={'contained'}
                    >
                        {t('auth.register-student.accept')}
                    </Button>)}

                    {registerLoading && <CircularProgress />}
                </DialogActions>
            </Dialog>
        </Stack>
    )
};

import React from 'react';
import {Box, Button, InputBase, Pagination, Stack, Tab, Tabs, Tooltip, Typography} from '@mui/material';
import {
    DataGrid,
    GridColDef, gridPageCountSelector,
    gridPageSelector, gridPaginationModelSelector, gridPaginationSelector,
    GridRowParams,
    useGridApiContext,
    useGridSelector
} from "@mui/x-data-grid";
import {GroupStatusChip} from "./GroupStatusChip";
import {formatDate} from "../../../global/helpers/text";
import { Link, useNavigate } from 'react-router-dom';
import {PeopleOutline} from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from "@mui/icons-material/Info";
import { PaginationArgs } from '../../pages/GroupsList';
import {useTranslation} from "react-i18next";
import {PaginationItem} from "@mui/lab";
import CustomDataGrid from "../../../global/components/CustomDataGrid";

interface GridPageChangeParams {
    page: number
}

interface GroupsTableProps {
    rows: any[];
    loading: boolean;
    filter: string;
    handleFilterChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    fetchGroups: () => void;
    status: number;
    setStatus: (status: number) => void;
    pagination: PaginationArgs,
    setPage: (page: number) => void
}

const NoRows = () => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', p: 5}}>
            <Typography>
                Нет групп с данным статусом
            </Typography>
        </Box>
    )
};

const GroupsTable = ({rows, loading, filter, handleFilterChange, fetchGroups, status, setStatus, pagination, setPage} : GroupsTableProps) => {

    const navigate = useNavigate();

    const {t} = useTranslation()

    const columns: GridColDef[] = [
        {
            field: 'empty',
            headerName: '',
            headerClassName: 'datagrid--header',
            maxWidth: 10,
            width: 10,
            sortable: false,
        },
        {
            field: 'id',
            headerName: 'ID',
            headerClassName: 'datagrid--header',
            maxWidth: 10,
            width: 10,
            sortable: false,
            renderCell: ((row) => {
                return <Typography fontSize={11} color={'gray'} sx={{
                    verticalAlign: 'center',
                }}>#{row.id}</Typography>
            })
        },
        {field: 'title', headerName: t('school_page.group_list.group_table.grid.title'), minWidth: 200, sortable: true},
        {field: 'category_id', headerName: t('school_page.group_list.group_table.grid.category'), width: 120, sortable: false},
        {
            field: 'stats', headerName: t('school_page.group_list.group_table.grid.stats'), width: 150, sortable: false,
            renderCell: ({row}) => {
                return <Stack flexDirection="row">
                    <PeopleOutline fontSize="small" sx={{color: 'gray', mr: 1}}/>
                    <Typography sx={{width: 35}} variant="body2">{row.students?.length}</Typography>
                </Stack>;
            }
        },
        {
            field: 'status', headerName: t('school_page.group_list.group_table.grid.status'), width: 200, sortable: false,
            renderCell: ({value}) => <GroupStatusChip status={value}/>
        },
        {
            field: 'start_date', headerName: t('school_page.group_list.group_table.grid.start_date'), width: 140, sortable: false,
            valueFormatter: ({value}) => formatDate(value)
        },
        {
            field: 'end_date', headerName: t('school_page.group_list.group_table.grid.end_date'), width: 140, sortable: false,
            valueFormatter: ({value}) => formatDate(value)
        },
        {
            field: 'new_tab', headerName: t('school_page.group_list.group_table.grid.action'), width: 300, sortable: false,
            renderCell: ({row}) => {
                return <Link to={`/groups/${row.id}`} target={'_blank'} onClick={(e) => {e.stopPropagation()}}>
                    <Button sx={{height: 'auto'}} variant={'contained'}>
                        {t('school_page.group_list.group_table.new-tab')}
                    </Button>
                </Link>;
            }        }
    ];

    const handleRowClick = (params: GridRowParams) => {
        navigate(`/groups/${params.row.id}`)
    }

    const tabs = {
        all: {id: 0, label: t('school_page.group_list.group_table.tabs.all')},
        created: {id: 1, label: t('school_page.group_list.group_table.tabs.created')},
        study: {id: 2, label: t('school_page.group_list.group_table.tabs.active')},
        attestation: {id: 3, label: t('school_page.group_list.group_table.tabs.exam')},
        finished: {id: 4, label: t('school_page.group_list.group_table.tabs.finished')}
    };

    return (
        <Box>
            <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'} sx={{mt: 2}} gap={1}>
                <Tooltip title={t('school_page.group_list.group_table.title')}>
                    <InfoIcon sx={{color: 'gray'}}/>
                </Tooltip>
                <InputBase
                    value={filter}
                    onChange={handleFilterChange}
                    placeholder={t('school_page.group_list.group_table.input')}
                    sx={{width: '400px'}}
                />
            </Stack>

            <Tabs
                value={status}
                onChange={(e, newValue) => setStatus(newValue)}
                variant="scrollable"
                scrollButtons={false}
                sx={{ml: 2}}
            >
                {Object.values(tabs).map(tab => <Tab label={tab.label} key={tab.id} value={tab.id}/>)}
            </Tabs>

            <CustomDataGrid
                columns={columns}
                rows={rows}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20,
                            page: pagination.page,
                        },
                    },
                }}
                paginationMode={'client'}
                rowCount={pagination.count}
                onPaginationModelChange={(params: GridPageChangeParams) => {
                    setPage(params.page);
                }}
                pageSizeOptions={[20]}
                autoHeight={true}
                loading={loading}
                onRowClick={handleRowClick}
                disableRowSelectionOnClick={true}
            />
        </Box>
    );
};

export default GroupsTable;